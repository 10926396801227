export const REPORT_TEAMS_FETCH_LIMIT = 100;

export const REPORT_TEAMS_UTILIZATION_LIMIT = 50;
export const REPORT_TEAMS_UTILIZATION_THRESHOLD = 0.5;
export const REPORT_TEAMS_UTILIZATION_COMPLIANT_START_DATE = 90;

export const REPORTS_VIEW_TYPES = {
  TEAMS: "teams",
  UTILIZATION: "utilization",
};

export const REPORTS_VIEW_TYPES_ARRAY = [
  {
    label: "Teams Overview",
    value: REPORTS_VIEW_TYPES.TEAMS,
  },
  {
    label: "Utilization Overview",
    value: REPORTS_VIEW_TYPES.UTILIZATION,
  },
];
