import { memo } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  Chip,
  FormControl,
} from "@material-ui/core";
import styled from "styled-components";
import { ItemWithOtherProps } from "types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

type SingleValuePickerProps = {
  items: ItemWithOtherProps[];
  item: string | number;
  itemsHash: Record<string, ItemWithOtherProps>;
  onValueChange: (event: React.ChangeEvent<any>) => void;
  inputLabel?: string;
  inputName?: string;
  fullWidth?: boolean;
  paddingTop?: string;
  disabled?: boolean;
  style?: object;
};

const SingleValuePickerV2 = ({
  items = [],
  item,
  itemsHash,
  onValueChange,
  inputName,
  inputLabel,
  fullWidth = false,
  paddingTop = "",
  style = {},
  disabled = false,
}: SingleValuePickerProps) => {
  const { label } = itemsHash[item] || {};

  return (
    <Container
      style={{ width: fullWidth ? "100%" : "unset", paddingTop, ...style }}
      className="single-value-picker-v2-container"
    >
      <InputLabel shrink htmlFor="select-multiple-native">
        {inputLabel || ""}
      </InputLabel>
      <Select
        fullWidth={fullWidth}
        name={inputName}
        disabled={disabled}
        value={item}
        onChange={onValueChange}
        renderValue={() => <div key={label}>{label}</div>}
      >
        {items.map((item: ItemWithOtherProps) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </Container>
  );
};

export default memo(SingleValuePickerV2);
