import {
  getAPI,
  getApiIntegrationServer,
  putAPI,
  postAPI,
  postApiIntegrationServer,
} from "services";

import { AxiosConfigType } from "types";
import { updateTeamParams, fetchTeamParams } from "ProfileModule/types";
import {
  fetchTeamsHistoricalUtilizationParams,
  FetchDataToIntegrationServerApiParams,
} from "ReportsModule/types";

import { REPORT_TEAMS_FETCH_LIMIT } from "@constants";

export const getReportsTeams = (
  config: AxiosConfigType = {},
  token: string = ""
) =>
  getAPI(
    "/teams/all",
    {
      ...config,
      params: {
        initial: true,
        offset: config.params?.offset,
        limit: REPORT_TEAMS_FETCH_LIMIT,
        is_active: false,
        order_by: "id",
        order_direction: "desc",
        ...config.params,
      },
    },
    token
  );

export const getSuperAdmins = (config = {}, token = "") =>
  getAPI(`/teams/super_admins`, config, token);

export const fetchDataToIntegrationServer = (
  {
    dataType,
    targetServiceId,
  }: Omit<FetchDataToIntegrationServerApiParams, "fetchFrom">,
  config = {}, 
  token = ""
) => {
  return getApiIntegrationServer(
    `/fetch-data/${dataType}/${targetServiceId}`,
    config,
    token
  );
};


type IntegrationForceUpdateApiParams = {
  mosaicTeamId: number;
  targetServiceId: number;
};

type IntegrationForceUpdateApiBody = {
  dataType: string;
  targetSide: boolean;
  recreateActive: boolean;
};

export const integrationServerForceUpdate = (
  { mosaicTeamId, targetServiceId }: IntegrationForceUpdateApiParams,
  body: IntegrationForceUpdateApiBody,
  config = {},
  token = ""
) =>
  postApiIntegrationServer(
    `/mappings/utils/force-update/${mosaicTeamId}/${targetServiceId}`,
    body,
    config,
    token
  );

export const updateTeam = (
  data: updateTeamParams,
  token: string,
  config: Record<string,any> = {}
) => putAPI(`/team`, data, config, token);

export const fetchTeamApiToken = (
  data: Record<any, any>,
  token: string,
  config: Record<string,any> = {}
) => postAPI(`/integrations/auth/key`, data, config, token);

export const createNewTeam = (
  credentials: Record<any, any>,
  config = {},
  token: string
) => postAPI("/signup", credentials, config, token);

export const getTeamsHistoricalUtilization = (
  params: fetchTeamsHistoricalUtilizationParams,
  token: string
) =>
  getAPI(
    "/historical_utilization/bulk",
    {
      params,
    },
    token
  );
