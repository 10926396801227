import React from "react";
import { startCase } from "lodash";
import styled from "styled-components";
import { LinearBuffer } from "../components/Progress";

const StyledListItem = styled.li`
  max-width: 25rem;
  word-break: break-word;
`;

/**
 * A utistyledlistitemty function that takes in a percentage number from 0 - 100, 
 * returns one of these value [error, warning, info, success]
 *  0-25% red
    25-50% orange
    50%-75% Yellow
    75%-95% Blue
    90%-100% Green
 * Note: Refer to Material UI component <Alert> in "@material-ui/core"
 * @param {number} - percentageNumber
 * @returns {string}
 */
export const getAlertDisplay = (percentageNumber: number): string => {
  if (percentageNumber <= 25 && percentageNumber >= 0) {
    return "error";
  } else if (
    (percentageNumber <= 50 && percentageNumber >= 25) ||
    (percentageNumber <= 75 && percentageNumber >= 50)
  ) {
    return "warning";
  } else if (percentageNumber > 75) {
    return "info";
  }

  return "";
};

export const calculateAlertSeverityByStatusCode = (statusCode: number) => {
  if (statusCode <= 299) return "success";

  if (statusCode <= 399) return "info";

  if (statusCode <= 499) return "warning";

  if (statusCode >= 500) return "error";

  return "success";
};

const renderSubRowArrayRecursive = (arr: Array<any>): JSX.Element => {
  return (
    <>
      {arr.map((v) => {
        if (typeof v === "object" && v !== null) {
          return renderSubRowRecursive(v);
        }

        if (Array.isArray(v)) return renderSubRowArrayRecursive(v);

        return v;
      })}
    </>
  );
};

export const renderSubRowRecursive = (records: Record<any, any> | any[]) => {
  if (!records) return;

  if (Array.isArray(records)) {
    return <ul>{renderSubRowArrayRecursive(records)}</ul>;
  }

  if (typeof records !== "object") {
    return JSON.stringify(records);
  }

  return (
    <ul>
      {Object.entries(records).map(([key, value]) => {
        if (Array.isArray(value)) {
          return (
            <StyledListItem>
              <b>{startCase(key)}</b>: {renderSubRowArrayRecursive(value)}
            </StyledListItem>
          );
        }

        if (typeof value === "object" && value !== null) {
          return (
            <>
              <StyledListItem>
                <b>{startCase(key)}</b>
              </StyledListItem>

              {renderSubRowRecursive(value)}
            </>
          );
        }

        return (
          <StyledListItem>
            <b>{startCase(key)}</b>: {"  "}
            {stringifyIfNotUndefinedNorNull(value)}
          </StyledListItem>
        );
      })}
    </ul>
  );
};

export const stringifyIfNotUndefinedNorNull = (
  value: any,
  falseReturnValue = "N/A"
) => {
  return value !== null && value !== undefined
    ? String(value)
    : falseReturnValue;
};

type ConditionallyRenderElement = {
  isLoading: boolean;
  error: any;
  renderError?: () => unknown;
  content: any;
  Loader?: any;
};

export const conditionallyRenderElement = ({
  isLoading,
  error,
  renderError,
  content,
  Loader,
}: ConditionallyRenderElement) => {
  if (isLoading) {
    return Loader || <LinearBuffer />;
  }

  if (error) {
    return renderError ? renderError() : error;
  }

  return content;
};
