import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { resetPasswordThunk as resetPassword } from "../../redux/thunk";
import { selectAuthLoadingState, selectAuthError } from "../../selectors";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const CheckEmailContainer = styled.div``;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
  .back-button {
    margin-top: 10px;
  }
`;

function ResetPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submittedEmail, setSubmittedEmail] = useState(false);

  const navigateToSignIn = () => {
    history.push("/auth/sign-in");
  };

  return (
    <Wrapper>
      <Helmet title="Reset Password" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Reset Password
      </Typography>
      {!submittedEmail ? (
        <>
          <Typography component="h2" variant="body1" align="center">
            Enter your email to reset your password
          </Typography>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            })}
            onSubmit={async (values) => {
              dispatch(resetPassword({ ...values }));
              setSubmittedEmail(true);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  type="email"
                  name="email"
                  label="Email Address"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Reset password
                </Button>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <CheckEmailContainer>
          <div>Please check your email for password reset link.</div>
        </CheckEmailContainer>
      )}
      <Button
        className="back-button"
        variant="contained"
        onClick={navigateToSignIn}
        fullWidth
      >
        Back to sign in
      </Button>
    </Wrapper>
  );
}

export default ResetPassword;
