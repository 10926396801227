import {
  green,
  red,
  orange,
  blue,
  purple,
  yellow,
  brown,
} from "@material-ui/core/colors";
import { keyBy, startCase } from "lodash";

export const MAPPING_STATUS = {
  ACTIVE: "active",
  DO_NOT_LINK: "do_not_link",
  CREATE_NEW_ON_MOSAIC: "CREATE_NEW_ON_MOSAIC",
  CREATE_NEW_ON_TARGET: "CREATE_NEW_ON_TARGET",
  UPDATE_ON_MOSAIC: "UPDATE_ON_MOSAIC",
  UPDATE_ON_TARGET: "UPDATE_ON_TARGET",
  BLOCK_ON_TARGET: "BLOCK_ON_TARGET",
  BLOCK_ON_MOSAIC: "BLOCK_ON_MOSAIC",
  NULL: "null",
};

export const PENDING_MAPPING_STATUS = {
  [MAPPING_STATUS.ACTIVE]: false,
  [MAPPING_STATUS.DO_NOT_LINK]: false,
  [MAPPING_STATUS.BLOCK_ON_TARGET]: false,
  [MAPPING_STATUS.BLOCK_ON_MOSAIC]: false,
  [MAPPING_STATUS.NULL]: false,

  [MAPPING_STATUS.CREATE_NEW_ON_MOSAIC]: true,
  [MAPPING_STATUS.CREATE_NEW_ON_TARGET]: true,
  [MAPPING_STATUS.UPDATE_ON_MOSAIC]: true,
  [MAPPING_STATUS.UPDATE_ON_TARGET]: true,
};

/** Mosaic */

export const PENDING_ON_MOSAIC = [
  {
    label: "Create New On Mosaic",
    value: MAPPING_STATUS.CREATE_NEW_ON_MOSAIC,
  },
  {
    label: "Update On Mosaic",
    value: MAPPING_STATUS.UPDATE_ON_MOSAIC,
  },
];

export const pendingOnMosaicHash = keyBy(PENDING_ON_MOSAIC, "value");

export const pendingOnMosaicStrings = Object.keys(pendingOnMosaicHash).map(
  (key) => key
);

/** Target */
export const PENDING_ON_TARGET = [
  {
    label: "Create New On Target",
    value: MAPPING_STATUS.CREATE_NEW_ON_TARGET,
  },
  {
    label: "Update On Target",
    value: MAPPING_STATUS.UPDATE_ON_TARGET,
  },
];

export const pendingOnTargetHash = keyBy(PENDING_ON_TARGET, "value");

export const pendingOnTargetStrings = Object.keys(pendingOnTargetHash).map(
  (key) => key
);

/** Mosaic & Target */
export const ALL_PENDING_MAPPING_STATUS = [
  ...PENDING_ON_MOSAIC,
  ...PENDING_ON_TARGET,
];

export const allPendingMappingStatusHash = keyBy(
  ALL_PENDING_MAPPING_STATUS,
  "value"
);

export const allPendingMappingStatusStrings = Object.keys(
  allPendingMappingStatusHash
).map((key) => key);

export const INTEGRATION_COLOR_MAPPING = {
  activity: red[500],
  client: orange[500],
  employee: green[500],
  phase: blue[500],
  project: purple[500],
  time_entry: yellow[500],
  work_plan: brown[500],
};

export const upcomingIntegration = new Set<string>(["github"]);

export const AVAILABLE_INTEGRATION_SERVICES = new Set<string>([
  "quickbooks-online",
  "quickbooks-desktop",
  "deltek-ajera-cloud",
  "deltek-ajera-hosted",
  "deltek-vision-cloud",
  "deltek-vision-hosted",
  "deltek-vantagepoint-cloud",
  "deltek-vantagepoint-hosted",
  "csv-agent",
  "jira-cloud",
  "github",
]);

// value and label are `Item` type keys. I.e: SingleValuePickerV2
export const integrations = [
  {
    label: "Quickbooks Online",
    id: "quickbooks-online",
    value: "quickbooks-online",
  },
  {
    label: "Quickbooks Desktop",
    id: "quickbooks-desktop",
    value: "quickbooks-desktop",
  },
  {
    label: "Deltek Ajera Cloud",
    id: "deltek-ajera-cloud",
    value: "deltek-ajera-cloud",
  },
  {
    label: "Deltek Ajera Hosted",
    id: "deltek-ajera-hosted",
    value: "deltek-ajera-hosted",
  },
  {
    label: "Deltek Vision Cloud",
    id: "deltek-vision-cloud",
    value: "deltek-vision-cloud",
  },
  {
    label: "Deltek Vision Hosted",
    id: "deltek-vision-hosted",
    value: "deltek-vision-hosted",
  },
  {
    label: "Deltek VantagePoint Cloud",
    id: "deltek-vantagepoint-cloud",
    value: "deltek-vantagepoint-cloud",
  },
  {
    label: "Deltek VantagePoint Hosted",
    id: "deltek-vantagepoint-hosted",
    value: "deltek-vantagepoint-hosted",
  },
  {
    label: "CSV Agent",
    id: "csv-agent",
    value: "csv-agent",
  },
  {
    label: "Jira Cloud",
    id: "jira-cloud",
    value: "jira-cloud",
  },
  {
    label: "Github",
    id: "github",
    value: "github",
  },
];

export const integrationsHash = keyBy(integrations, "id");

/** For Integration API call */
export const INTEGRATION_SERVICE_NAMES = {
  QUICKBOOKS_ONLINE: "quickbooks-online",
  QUICKBOOKS_DESKTOP: "quickbooks-desktop",
  DELTEK_AJERA_CLOUD: "deltek-ajera-cloud",
  DELTEK_AJERA_HOSTED: "deltek-ajera-hosted",
  DELTEK_VISION_CLOUD: "deltek-vision-cloud",
  DELTEK_VISION_HOSTED: "deltek-vision-hosted",
  DELTEK_VANTAGEPOINT_CLOUD: "deltek-vantagepoint-cloud",
  DELTEK_VANTAGEPOINT_HOSTED: "deltek-vantagepoint-hosted",
  CSV_AGENT: "csv-agent",
  JIRA_CLOUD: "jira-cloud",
  GITHUB: "github",
};

/** For visual display on table, graph etc...  */

export const INTEGRATION_SERVICE_NAMES_DISPLAY = {
  [INTEGRATION_SERVICE_NAMES.QUICKBOOKS_ONLINE]: "Quickbooks Online",
  [INTEGRATION_SERVICE_NAMES.QUICKBOOKS_DESKTOP]: "Quickbooks Desktop",
  [INTEGRATION_SERVICE_NAMES.DELTEK_VISION_CLOUD]: "Deltek Vision Cloud",
  [INTEGRATION_SERVICE_NAMES.DELTEK_VISION_HOSTED]: "Deltek Vision Hosted",
  [INTEGRATION_SERVICE_NAMES.DELTEK_AJERA_HOSTED]: "Deltek Ajera Hosted",
  [INTEGRATION_SERVICE_NAMES.DELTEK_AJERA_CLOUD]: "Deltek Ajera Cloud",
  [INTEGRATION_SERVICE_NAMES.DELTEK_VANTAGEPOINT_CLOUD]:
    "Deltek Vantagepoint Cloud",
  [INTEGRATION_SERVICE_NAMES.DELTEK_VANTAGEPOINT_HOSTED]:
    "Deltek Vantagepoint Hosted",

  [INTEGRATION_SERVICE_NAMES.CSV_AGENT]: "CSV Agent",
  [INTEGRATION_SERVICE_NAMES.JIRA_CLOUD]: "Jira Cloud",
  [INTEGRATION_SERVICE_NAMES.GITHUB]: "Github",
};

export const INTEGRATION_SERVICE_MAPPING = {
  [INTEGRATION_SERVICE_NAMES_DISPLAY[
    INTEGRATION_SERVICE_NAMES.QUICKBOOKS_ONLINE
  ]]: "quickbooks-online",
  [INTEGRATION_SERVICE_NAMES_DISPLAY[
    INTEGRATION_SERVICE_NAMES.QUICKBOOKS_DESKTOP
  ]]: "quickbooks-desktop",
  [INTEGRATION_SERVICE_NAMES_DISPLAY[
    INTEGRATION_SERVICE_NAMES.DELTEK_AJERA_CLOUD
  ]]: "deltek-ajera-cloud",
  [INTEGRATION_SERVICE_NAMES_DISPLAY[
    INTEGRATION_SERVICE_NAMES.DELTEK_AJERA_HOSTED
  ]]: "deltek-ajera-hosted",
  [INTEGRATION_SERVICE_NAMES_DISPLAY[
    INTEGRATION_SERVICE_NAMES.DELTEK_VISION_CLOUD
  ]]: "deltek-vision-cloud",
  [INTEGRATION_SERVICE_NAMES_DISPLAY[
    INTEGRATION_SERVICE_NAMES.DELTEK_VISION_HOSTED
  ]]: "deltek-vision-hosted",
  [INTEGRATION_SERVICE_NAMES_DISPLAY[
    INTEGRATION_SERVICE_NAMES.DELTEK_VANTAGEPOINT_CLOUD
  ]]: "deltek-vantagepoint-cloud",

  [INTEGRATION_SERVICE_NAMES_DISPLAY[
    INTEGRATION_SERVICE_NAMES.DELTEK_VANTAGEPOINT_HOSTED
  ]]: "deltek-vantagepoint-hosted",
  [INTEGRATION_SERVICE_NAMES_DISPLAY[INTEGRATION_SERVICE_NAMES.CSV_AGENT]]:
    "csv-agent",
  [INTEGRATION_SERVICE_NAMES_DISPLAY[INTEGRATION_SERVICE_NAMES.JIRA_CLOUD]]:
    "jira-cloud",
  [INTEGRATION_SERVICE_NAMES_DISPLAY[INTEGRATION_SERVICE_NAMES.GITHUB]]:
    "github",
};

export const INTEGRATION_SERVICE_METRICS_API_DEFAULT_VALUES = {
  OFFSET: 0,
  LIMIT: 1000,
};

// View By
export const GENERAL = "general";
export const TEAM = "team";

// Error Log type, later we will differentiate between IS to BE errors vs IS to Agent errors
export const NETWORK = "network-errors";
export const AGENT = "agent-errors";

// Request Log type
export const API_REQUEST_LOG = "api-request-log";
export const NETWORK_LOG = "network-log";

// PROBABLY have to rename network and agent and put inside a hash maybe?
export const errorTypes = [
  {
    value: "",
    label: "None",
  },
  {
    value: NETWORK,
    label: "Integration Server",
  },
  {
    value: AGENT,
    label: "Agent",
  },
];

export const errorTypesHash = keyBy(errorTypes, "value");

export const defaultErrorType = errorTypes[0];
