import { getAPI, putAPI, postAPI, getApiIntegrationServer } from "services/api";
import {
  resetMemberPasswordParams,
  fetchAgentTokenParams,
  checkIntegrationConnectionParams,
  provisionCustomerParams,
  setSingleUserTemporaryPasswordParams,
  bulkActivateAccountsParams,
} from "ProfileModule/types";

export const getTeamMember = (
  teamId: number,
  config: object = {},
  token: string = ""
) => getAPI(`/teams/${teamId}/team_memberships`, config, token);

export const updateTeamMember = (
  teamMembershipId: number,
  data: Record<any, any>,
  config: object = {},
  token: string
) => putAPI(`/team_memberships/${teamMembershipId}`, data, config, token);

export const getTeamByID = (config: object = {}, token: string = "") =>
  getAPI(`/teams/all`, config, token);

export const resetMemberPassword = (
  body: resetMemberPasswordParams,
  config: object = {},
  token: string
) => postAPI("/reset_passwords", body, config, token);

export const getAgentToken = ({
  targetService,
  teamId,
}: fetchAgentTokenParams) =>
  getApiIntegrationServer(
    `/forward/${targetService}/provision-account/${teamId}`,
    {}
  );

// TO COME
// export const checkIntegrationConnection = ({
//   targetService,
//   teamId,
// }: checkIntegrationConnectionParams) =>
//   getApiIntegrationServer(
//     `/forward/${targetService}/provision-company/${teamId}`,
//     {}
//   );

export const provisionCustomer = ({
  targetService,
  teamId,
}: provisionCustomerParams) =>
  getApiIntegrationServer(
    `/forward/${targetService}/provision-company/${teamId}`,
    {}
  );

export const setSingleUserTemporaryPassword = (
  body: setSingleUserTemporaryPasswordParams,
  config: Record<string, any>,
  token: string
) => postAPI(`/auth_server/account_set_password`, body, config, token);

export const bulkActivateAccounts = (
  body: bulkActivateAccountsParams,
  config: Record<string, any>,
  token: string
) => postAPI(`/account/bulk-force-activate`, body, config, token);
