import { GenericTeamInterface } from "../types";
import { sortBy, keyBy } from "lodash";
import { REPORTS_INTEGRATION_DETAILS_PATH } from "@constants";

/**
 * A utility function that takes an array of teams
 * And added targetServices list to the team,
 * The team also exists on BE, but not all teams have integrations
 * Thus, this function should only be used for teams that are fetched from IS
 * (i.e: fetched from service metrics endpoints)
 * The goal is to avoid calling IS API with all the teams that doesn't have integration
 * @returns {Record<number,object>}
 */
export const makeIntegrationTeamInfoMappings = (
  teams: GenericTeamInterface[],
  targetService: string
): Record<number, object> => {
  const integrationTeamsInfoMapping = teams.map((team) => {
    // The team can have more than 1 type of target service
    if (!team.targetServices) {
      return {
        ...team,
        targetServices: [targetService],
      };
    }
    const targetServiceSet = new Set(team.targetServices);

    return {
      ...team,
      targetServices: targetServiceSet.has(targetService)
        ? team.targetServices
        : [...team.targetServices, targetService],
    };
  });
  return keyBy(integrationTeamsInfoMapping, "id");
};

export const getTeamsWithIntegration = (teams: GenericTeamInterface[]) => {
  return teams.filter((team) => team.targetServices);
};

export const getTeamIntegrationPath = (
  teamId: number,
  integrationName: string,
  targetServiceId: string
) => {
  return `${REPORTS_INTEGRATION_DETAILS_PATH}/teams/${teamId}/targetServices/${integrationName}/targetServiceId/${targetServiceId}`;
};
