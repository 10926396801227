import { createAction } from "@reduxjs/toolkit";

export const setSelectedTeam = createAction("setSelectedTeam", (params) => ({
  payload: params,
}));

export const setTargetService = createAction("setTargetService", (params) => ({
  payload: params,
}));

export const updateProfileState = createAction(
  "updateProfileState",
  (params) => ({
    payload: params,
  })
);
