import keyBy from "lodash/keyBy";

export const trialDurationArray = [
  {
    value: 15,
    label: "15",
  },
  {
    value: 30,
    label: "30",
  },
];

export const trialDurationHash = keyBy(trialDurationArray, "value");

/// Need to replace with actual ones from API
export const dummyDiscount = [
  {
    value: 0,
    label: "None (0%)"
  },
  {
    value: 15,
    label: "15%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export const dummyDiscountHash = keyBy(dummyDiscount, "value");
