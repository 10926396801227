import * as ReportService from "ReportsModule/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Types from "ReportsModule/types";
import { pickBy, identity } from "lodash";
import { AppStateType } from "reducers";

/* -------------------------------------------------------------------------- */
export const integrationForceUpdate = createAsyncThunk(
  "integrationForceUpdate",
  async (
    params: Types.IntegrationForceUpdateApiParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;

      const auth_token = user?.auth_token;
      const {
        recreateActive,
        mosaicTeamId,
        targetServiceId,
        dataType,
        targetSide,
      } = params;
      const body = { dataType, targetSide, recreateActive };
      const response = await ReportService.integrationServerForceUpdate(
        { mosaicTeamId, targetServiceId },
        body,
        {},
        auth_token
      );
      return {
        data: response.data,
        status: response.status,
      };
    } catch ({ data }) {
      return {
        status: data.statusCode,
        data: data.message,
      };
    }
  }
);

/* -------------------------------------------------------------------------- */
export const fetchDataToIntegrationServer = createAsyncThunk(
  "fetchDataToIntegrationServer",
  async (
    params: Types.FetchDataToIntegrationServerApiParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const {
        dataType,
        targetServiceId,
        dateAfter,
        startDate,
        projectId,
      } = params;
      const body = {
        dataType,
        targetServiceId,
      };
      const queryParams = pickBy({ dateAfter, startDate, projectId }, identity);
      const response = await ReportService.fetchDataToIntegrationServer(
        body,
        queryParams,
        auth_token
      );
      return {
        data: response.data,
        status: response.status,
      };
    } catch ({ data }) {
      return {
        status: data.statusCode,
        data: data.message,
      };
    }
  }
);
