import { postAPI, getAPI } from "services";

import {
  ResetPasswordType,
  ConfirmPasswordResetType,
  SignInType,
  SignUpType,
} from "types";

export const signIn = (credentials: SignInType, config = {}) =>
  postAPI("/auth/login", credentials, config);

export const getAuthToken = (tempToken: string, config = {}) =>
  getAPI("/auth/get_auth_token", config, tempToken);

export const resetPassword = (credentials: ResetPasswordType, config = {}) =>
  postAPI("/reset_password", credentials, config);

export const getMe = (token: string, config = {}) =>
  getAPI("/me", config, token);

export const confirmPasswordReset = (
  credentials: ConfirmPasswordResetType,
  config = {}
) => postAPI("/account/confirm_password_reset", credentials, config);

export const getTempToken = (auth_token: string, config: object = {}) =>
  getAPI(`/auth/get_temp_token`, config, auth_token);

export const getImpersonateToken = (
  accountId: number | string,
  config: object = {},
  token: string = ""
) => getAPI(`/impersonate/${accountId}`, config, token);
