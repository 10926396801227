import { Draft, createReducer } from "@reduxjs/toolkit";
import * as thunkActions from "thunk";
import * as actions from "actionCreators";
import {
  IntegrationServiceMetricsDataPerServiceInterface,
  IntegrationServiceMetricsDataInterface,
  IntegrationServiceMetricsTotalDataInterface,
  RecentErrorsType,
  IntegrationServiceMetricsTotalDataInstance,
  ActiveIntegrations,
} from "types";

type InitialStateType = {
  service_metrics: IntegrationServiceMetricsDataInterface;
  service_metrics_sum: IntegrationServiceMetricsTotalDataInstance[];
  agent_error_log: RecentErrorsType[];
  network_error_log: Record<any, any>[];
  active_integrations: Array<ActiveIntegrations>;

  requestStatusesAndErrors: {
    [x: string]: {
      isRequesting: boolean;
      error: Record<string, any>;
    };
  };
};

const initialState: InitialStateType = {
  service_metrics: {
    limit: 0,
    targetServiceAndTeamIds: [],
    data: [],
    targetService: "",
  },
  service_metrics_sum: [],
  agent_error_log: [],
  network_error_log: [],
  active_integrations: [],

  requestStatusesAndErrors: {
    // Example with boolean
    [thunkActions.fetchTeamIntegrationList.typePrefix]: {
      isRequesting: false,
      error: {},
    },
  },
};

export default createReducer(initialState, (builder) => {
  /* ---------------------------- Non Async Request --------------------------- */
  builder.addCase(actions.setGeneralIntegrationState, (state, action) => {
    state = {
      ...state,
      ...action.payload,
    };
  });

  /* --------------------------------Service metrics------------------------------------------ */
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetrics.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetrics.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetrics.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetrics.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      const { arg } = meta;
      const { targetService, limit } = arg;

      const nextState = {
        targetService,
        limit,
        data: payload.results,
        targetServiceAndTeamIds: payload.teams,
      };
      state.service_metrics = nextState;
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetrics.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetrics.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.service_metrics = {
        targetService: "",
        limit: 0,
        targetServiceAndTeamIds: [],
        data: [],
      };
    }
  );

  /* --------------------------------Service metrics sum (total counts)------------------------------------------ */
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetricsTotal.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetricsTotal.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetricsTotal.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetricsTotal.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.service_metrics_sum = payload;
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetricsTotal.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetricsTotal.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.service_metrics_sum = [];
    }
  );

  /* ------------------------------- Active Integration (IS) ------------------------------ */
  builder.addCase(
    thunkActions.fetchActiveIntegrations.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchActiveIntegrations.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchActiveIntegrations.fulfilled,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchActiveIntegrations.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.active_integrations = action.payload;
    }
  );
  builder.addCase(
    thunkActions.fetchActiveIntegrations.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchActiveIntegrations.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.active_integrations = [];
    }
  );

  /* ---------------------------- Network Error Log --------------------------- */
  builder.addCase(
    thunkActions.fetchNetworkErrorLog.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchNetworkErrorLog.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchNetworkErrorLog.fulfilled,
    (state, action) => {
      const {
        meta: { arg },
        payload,
      } = action;
      const { isInitialFetch } = arg;
      state.requestStatusesAndErrors[
        thunkActions.fetchNetworkErrorLog.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.network_error_log = isInitialFetch
        ? payload
        : [...state.network_error_log, ...payload];
    }
  );
  builder.addCase(
    thunkActions.fetchNetworkErrorLog.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchNetworkErrorLog.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.network_error_log = [];
    }
  );

  /* ------------------------------- Agent Error Log ------------------------------ */
  builder.addCase(thunkActions.fetchAgentErrorLog.pending, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchAgentErrorLog.typePrefix
    ] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(
    thunkActions.fetchAgentErrorLog.fulfilled,
    (state, action) => {
      const {
        meta: { arg },
        payload,
      } = action;
      const { isInitialFetch } = arg;
      state.requestStatusesAndErrors[
        thunkActions.fetchAgentErrorLog.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.agent_error_log = isInitialFetch
        ? payload
        : [...state.agent_error_log, ...payload];
    }
  );
  builder.addCase(thunkActions.fetchAgentErrorLog.rejected, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchAgentErrorLog.typePrefix
    ] = {
      isRequesting: false,
      error: action.error,
    };
    state.agent_error_log = [];
  });
});
