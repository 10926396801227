import { Draft, createReducer } from "@reduxjs/toolkit";
import * as thunkActions from "thunk";
import * as actions from "actionCreators";
import {
  ForceUpdateResponseType,
  FetchToIntegrationServerResponseType,
  TeamsHistoricalUtilization,
} from "ReportsModule/types";
import * as Types from "types";
import keyBy from "lodash/keyBy";

type InitialStateType = {
  teams: Types.GenericTeamInterface[];
  teamsInfoMapping: Record<any, any>; // keyBy id
  super_admins: Array<Types.SuperAdminInterface>;
  fetchToIntegrationServerResponse: {
    agent: FetchToIntegrationServerResponseType;
    backend: FetchToIntegrationServerResponseType;
  };
  //
  teams_historical_utilization: TeamsHistoricalUtilization;
  teams_historical_utilization_order: number[];
  //
  forceUpdateResponse: ForceUpdateResponseType;
  requestStatusesAndErrors: {
    [x: string]: {
      isRequesting: any;
      error: any;
    };
  };
};

const initialState: InitialStateType = {
  teams: [],
  teamsInfoMapping: {},
  super_admins: [],
  teams_historical_utilization: {},
  teams_historical_utilization_order: [],
  fetchToIntegrationServerResponse: {
    agent: {
      statusCode: 0,
      data: "",
    },
    backend: {
      statusCode: 0,
      data: "",
    },
  },
  forceUpdateResponse: {
    data: "",
    statusCode: 0,
  },
  requestStatusesAndErrors: {
    // Example
    [thunkActions.fetchTeamIntegrationList.typePrefix]: {
      isRequesting: false,
      error: {},
    },
    [thunkActions.fetchDataToIntegrationServer.typePrefix]: {
      isRequesting: {
        agent: false,
        backend: false,
      },
      error: {
        agent: {},
        backend: {},
      },
    },
  },
};

export default createReducer(initialState, (builder) => {
  /* ---------------------------- Non Async Request --------------------------- */
  builder.addCase(actions.updateTeamsInfoMapping, (state, { payload }) => {
    state.teamsInfoMapping = {
      ...(state?.teamsInfoMapping || {}),
      ...payload,
    };
  });

  /* ------------------------------- Fetch Teams ------------------------------ */
  builder.addCase(thunkActions.fetchReportsTeams.pending, (state, { meta }) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchReportsTeams.typePrefix
    ] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(
    thunkActions.fetchReportsTeams.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchReportsTeams.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      const { isInitialFetch, teams = [] } = payload;
      state.teams = isInitialFetch
        ? [...teams]
        : [...(state.teams as Types.GenericTeamInterface[]), ...teams];
      state.teamsInfoMapping = {
        ...state.teamsInfoMapping,
        ...keyBy(teams, "id"),
      };
    }
  );
  builder.addCase(
    thunkActions.fetchReportsTeams.rejected,
    (state, { meta, error }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchReportsTeams.typePrefix
      ] = {
        isRequesting: false,
        error,
      };
      state = {
        ...state,
        teams: [],
      };
    }
  );

  /* ------------------------------- Fetch Data to IS ------------------------------ */
  builder.addCase(
    thunkActions.fetchDataToIntegrationServer.pending,
    (state, { meta }) => {
      const {
        arg: { fetchFrom },
      } = meta;
      const requestStatusByFetchFrom =
        state.requestStatusesAndErrors[
          thunkActions.fetchDataToIntegrationServer.typePrefix
        ].isRequesting;
      const errorByFetchFrom =
        state.requestStatusesAndErrors[
          thunkActions.fetchDataToIntegrationServer.typePrefix
        ].isRequesting;

      state.requestStatusesAndErrors[
        thunkActions.fetchDataToIntegrationServer.typePrefix
      ] = {
        isRequesting: {
          ...requestStatusByFetchFrom,
          [fetchFrom]: true,
        },
        error: {
          ...errorByFetchFrom,
          [fetchFrom]: {},
        },
      };
    }
  );
  builder.addCase(
    thunkActions.fetchDataToIntegrationServer.fulfilled,
    (state, { meta, payload }) => {
      const {
        arg: { fetchFrom },
      } = meta;
      const requestStatusByFetchFrom =
        state.requestStatusesAndErrors[
          thunkActions.fetchDataToIntegrationServer.typePrefix
        ].isRequesting;
      const errorByFetchFrom =
        state.requestStatusesAndErrors[
          thunkActions.fetchDataToIntegrationServer.typePrefix
        ].isRequesting;

      state.requestStatusesAndErrors[
        thunkActions.fetchDataToIntegrationServer.typePrefix
      ] = {
        isRequesting: {
          ...requestStatusByFetchFrom,
          [fetchFrom]: false,
        },
        error: {
          ...errorByFetchFrom,
          [fetchFrom]: {},
        },
      };

      state.fetchToIntegrationServerResponse = {
        ...state.fetchToIntegrationServerResponse,
        [fetchFrom]: {
          statusCode: payload.status,
          data: payload.data,
        },
      };
    }
  );
  /* ------------------------------- IS Force Update ------------------------------ */
  builder.addCase(
    thunkActions.integrationForceUpdate.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.integrationForceUpdate.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.integrationForceUpdate.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.integrationForceUpdate.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };

      state.forceUpdateResponse = {
        statusCode: payload.status,
        data: payload.data,
      };
    }
  );
  /* ------------------------------- Super Admin ------------------------------ */
  // FETCH
  builder.addCase(thunkActions.fetchSuperAdmins.pending, (state, action) => {
    state.requestStatusesAndErrors[thunkActions.fetchSuperAdmins.typePrefix] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(thunkActions.fetchSuperAdmins.fulfilled, (state, action) => {
    state.requestStatusesAndErrors[thunkActions.fetchSuperAdmins.typePrefix] = {
      isRequesting: false,
      error: {},
    };
    state.super_admins = action.payload;
  });
  builder.addCase(thunkActions.fetchSuperAdmins.rejected, (state, action) => {
    state.requestStatusesAndErrors[thunkActions.fetchSuperAdmins.typePrefix] = {
      isRequesting: false,
      error: action.error,
    };
    state.super_admins = [];
  });
  // UPDATE
  builder.addCase(thunkActions.updateSuperAdmin.pending, (state, action) => {
    state.requestStatusesAndErrors[thunkActions.updateSuperAdmin.typePrefix] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(
    thunkActions.updateSuperAdmin.fulfilled,
    (state, { meta, payload }) => {
      const oldSuperAdmin = meta.arg;
      const { teamMembershipId } = oldSuperAdmin;
      const updatedSuperAdmin = payload.team_member.account;
      state.requestStatusesAndErrors[
        thunkActions.updateSuperAdmin.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.super_admins = state.super_admins.map((super_admin) =>
        super_admin.id === teamMembershipId
          ? { ...super_admin, ...updatedSuperAdmin, id: teamMembershipId }
          : super_admin
      );
    }
  );
  builder.addCase(thunkActions.updateSuperAdmin.rejected, (state, action) => {
    state.requestStatusesAndErrors[thunkActions.updateSuperAdmin.typePrefix] = {
      isRequesting: false,
      error: action.error,
    };
  });
  /* ---------------------------- Teams Utilization --------------------------- */
  builder.addCase(
    thunkActions.fetchTeamsHistoricalUtilization.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamsHistoricalUtilization.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchTeamsHistoricalUtilization.fulfilled,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamsHistoricalUtilization.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      const { isInitialFetch } = action.meta.arg;
      state.teams_historical_utilization = isInitialFetch
        ? action.payload
        : {
            ...state.teams_historical_utilization,
            ...action.payload,
          };

      const nextOrders = isInitialFetch
        ? Object.keys(action.payload).map((id) => +id)
        : [
            ...state.teams_historical_utilization_order,
            ...Object.keys(action.payload),
          ].map((id) => +id);
      state.teams_historical_utilization_order = nextOrders;
    }
  );
  builder.addCase(
    thunkActions.fetchTeamsHistoricalUtilization.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamsHistoricalUtilization.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
    }
  );
  /* ------------------------ Fetch Teams Info Mapping ------------------------ */
  builder.addCase(
    thunkActions.fetchTeamsForTeamsInfoMapping.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamsForTeamsInfoMapping.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchTeamsForTeamsInfoMapping.fulfilled,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamsForTeamsInfoMapping.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.teamsInfoMapping = {
        ...state.teamsInfoMapping,
        ...keyBy(action.payload, "id"),
      };
    }
  );
  builder.addCase(
    thunkActions.fetchTeamsForTeamsInfoMapping.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamsForTeamsInfoMapping.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
    }
  );
});
