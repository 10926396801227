import { keyBy } from "lodash";

export const INTERVAL = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  // YEAR: 'year',
};

export const INTERVAL_SET = new Set<string>(["day", "week", "month"]);

export const INTERVAL_DISPLAY = {
  [INTERVAL.DAY]: "Day",
  [INTERVAL.WEEK]: "Week",
  [INTERVAL.MONTH]: "Month",
  // [INTERVAL.YEAR]: 'Year',
};

export const intervals: Array<IntervalType> = [
  {
    id: INTERVAL.DAY,
    label: "Since 1 day ago",
    value: 1,
  },
  {
    id: INTERVAL.WEEK,
    label: "Since 7 days ago",
    value: 7,
  },
  {
    id: INTERVAL.MONTH,
    label: "Since 30 days ago",
    value: 30,
  },
];

export type IntervalType = {
  id: string;
  label: string;
  value: number;
};

export const intervalsHash = keyBy(intervals, "id");