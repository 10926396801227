import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "reducers";
import { redirectToLoginScreen, } from "utils";
import { signOut } from "actionCreators";
import { selectIsSuperAdmin } from "selectors";

const SecurityLayer = ({ children }: { children: any }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppStateType) => state.authReducer.user);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  /**
   * This condition is only checking whether
   * this user is a super admin in their own default team
   * For accuracy, recommend BE to send a flag to indicate 
   * if this user is a super admin when calling `/me`
   */
  if (!user || !isSuperAdmin) {
    setTimeout(async () => {
      dispatch(signOut());
      redirectToLoginScreen();
    }, 2000);

    return (
      <div>
        You don't have permission to access Galaxy because you are not a super
        admin. Redirecting to login screen...
      </div>
    );
  }

  return <div>{children}</div>;
};

export default SecurityLayer;
