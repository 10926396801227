import keyBy from "lodash/keyBy";
import startCase from "lodash/startCase";
import { DataTypesDisplayInterface, Item } from "types";

/* ----------------------- Integration data type keys ----------------------- */

export const ACTIVITY = "activity";
export const CLIENT = "client";
export const EMPLOYEE = "employee";
export const PHASE = "phase";
export const PROJECT = "project";
export const TIME_ENTRY = "time_entry";
export const WORK_PLAN = "work_plan";
export const BUDGET_ESTIMATE = "budget_estimate";
export const TASK = "task";
export const EVENTS = "events";

export const DATA_TYPES: Array<string> = [
  ACTIVITY,
  CLIENT,
  EMPLOYEE,
  PHASE,
  PROJECT,
  TIME_ENTRY,
  WORK_PLAN,
  BUDGET_ESTIMATE,
  TASK,
  EVENTS,
];

export const dataTypesItems = DATA_TYPES.reduce(
  (acc: Array<Item>, dataType: string) => {
    const dataTypeItem = {
      label: startCase(dataType),
      value: dataType,
    };

    acc.push(dataTypeItem);

    return acc;
  },
  []
);

/** This is integration data type, not Backend data type  */
export const dataTypesHash = keyBy(dataTypesItems, "value");

export const DATA_TYPES_DISPLAY: DataTypesDisplayInterface = {
  [ACTIVITY]: "Activity",
  [CLIENT]: "Client",
  [EMPLOYEE]: "Employee",
  [PHASE]: "Phase",
  [PROJECT]: "Project",
  [TIME_ENTRY]: "Time Entry",
  [WORK_PLAN]: "Workplan",
  [BUDGET_ESTIMATE]: "Budget Estimate",
  [TASK]: "Task",
  [EVENTS]: "Event",
};

export const DATA_TYPES_TO_TELEMETRY_LAST_UPDATES = {
  activity: {
    key: "Item",
    label: DATA_TYPES_DISPLAY.activity,
    updatedAt: "item_updated_at",
  },
  client: {
    key: "Customer",
    label: DATA_TYPES_DISPLAY.client,
    updatedAt: "vendor_updated_at",
  },
  employee: {
    key: "Employee",
    label: DATA_TYPES_DISPLAY.employee,
    updatedAt: "employee_updated_at",
  },
  phase: {
    key: "Customer",
    label: DATA_TYPES_DISPLAY.phase,
    updatedAt: "customer_updated_at",
  },
  project: {
    key: "Customer",
    label: DATA_TYPES_DISPLAY.project,
    updatedAt: "customer_updated_at",
  },
  time_entry: {
    key: "TimeEntry",
    label: DATA_TYPES_DISPLAY.time_entry,
    updatedAt: "time_entry_updated_at",
  },
  work_plan: {
    key: "WorkPlan",
    label: DATA_TYPES_DISPLAY.work_plan,
    // updatedAt: "item_updated_at"
  },
  budget_estimate: {
    key: "budget_estimate",
    label: DATA_TYPES_DISPLAY.budget_estimate,
  },
  task: {
    key: "task",
    label: DATA_TYPES_DISPLAY.task,
  },
  events: {
    key: "events",
    label: DATA_TYPES_DISPLAY.events,
  },
};

export const DATA_TYPES_MAPPING = {
  [DATA_TYPES_DISPLAY.activity]: "activity",
  [DATA_TYPES_DISPLAY.client]: "client",
  [DATA_TYPES_DISPLAY.employee]: "employee",
  [DATA_TYPES_DISPLAY.phase]: "phase",
  [DATA_TYPES_DISPLAY.project]: "project",
  [DATA_TYPES_DISPLAY.time_entry]: "time_entry",
  [DATA_TYPES_DISPLAY.work_plan]: "workplan",
  [DATA_TYPES_DISPLAY.budget_estimate]: "budget_estimate",
  [DATA_TYPES_DISPLAY.task]: "task",
  [DATA_TYPES_DISPLAY.events]: "events",
};

/* ------------------------- Backend Data type keys ------------------------- */
export const BACKEND_DATA_TYPES: Array<string> = ["mosaic-time_entry"];

export const backendDataTypesItems = [
  {
    label: "Time Entry",
    value: "mosaic-time_entry",
  },
];

export const backendDataTypesHash = keyBy(backendDataTypesItems, "value");
