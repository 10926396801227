import { createActionTypes } from "../redux/actionCreators";
import { keyBy } from "lodash";

// TEAM Member Table type
export const MEMBER = "member";
export const SUPER_ADMIN = "super_admin";

// MEMBER table view
export const MEMBER_TABLE_VIEWS = {
  UTILIZATION: 'utilization',
  ALL_MEMBERS: 'all_members',
};
// MEMBER_TABLE_VIEWS.UTILIZATION

export const MEMBER_TABLE_VIEWS_ARRAY = [
  {
    label: "Members",
    value: MEMBER_TABLE_VIEWS.ALL_MEMBERS,
    hideSearchbar: false,
  },
  {
    label: "Utilization",
    value: MEMBER_TABLE_VIEWS.UTILIZATION,
    hideSearchbar: true,
  },
];

export const MEMBER_TABLE_VIEWS_HASH = keyBy(MEMBER_TABLE_VIEWS_ARRAY, 'value');

export const RESET_MEMBER_PASSWORD = createActionTypes("RESET_MEMBER_PASSWORD");
