/* eslint-disable import/first */
import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
} from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../components/AuthGuard";

// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Components components
import Accordion from "../pages/components/Accordion";
import Alerts from "../pages/components/Alerts";
import Avatars from "../pages/components/Avatars";
import Badges from "../pages/components/Badges";
import Buttons from "../pages/components/Buttons";
import Cards from "../pages/components/Cards";
import Chips from "../pages/components/Chips";
import Dialogs from "../pages/components/Dialogs";
import Lists from "../pages/components/Lists";
import Menus from "../pages/components/Menus";
import Pagination from "../pages/components/Pagination";
import Progress from "../components/Progress";
import Snackbars from "../pages/components/Snackbars";
import Tooltips from "../pages/components/Tooltips";

// Custom components
const Teams = async(() => import("src/modules/ReportsModule"));
const TeamIntegration = async(() => import("IntegrationModule/Team"));
const GeneralIntegration = async(() => import("IntegrationModule/General"));
const PendingEntity = async(() => import("IntegrationModule/PendingEntity"));
const Logs = async(() => import("IntegrationModule/Logs"));
const Administration = async(() => import("ReportsModule/Administration"));
const Developer = async(() => import("ReportsModule/Developer"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));
const SaaS = async(() => import("../pages/dashboards/SaaS"));

// Forms components
import SelectionCtrls from "../pages/forms/SelectionControls";
import Selects from "../pages/forms/Selects";
import TextFields from "../pages/forms/TextFields";
const Pickers = async(() => import("../pages/forms/Pickers"));
const Dropzone = async(() => import("../pages/forms/Dropzone"));
const Editors = async(() => import("../pages/forms/Editors"));
const Formik = async(() => import("../pages/forms/Formik"));

// Icons components
import MaterialIcons from "../pages/icons/MaterialIcons";
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
import Blank from "../pages/pages/Blank";
import InvoiceDetails from "../pages/pages/InvoiceDetails";
import InvoiceList from "../pages/pages/InvoiceList";
import Orders from "../pages/pages/Orders";
import Pricing from "../pages/pages/Pricing";
import Settings from "../pages/pages/Settings";
import Projects from "../pages/pages/Projects";
import Chat from "../pages/pages/Chat";
const Profile = async(() => import("ProfileModule"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Calendar = async(() => import("../pages/pages/Calendar"));

// Tables components
import SimpleTable from "../pages/tables/SimpleTable";
import AdvancedTable from "../pages/tables/AdvancedTable";

// Chart components
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
import Welcome from "../pages/docs/Welcome";
import GettingStarted from "../pages/docs/GettingStarted";
import EnvironmentVariables from "../pages/docs/EnvironmentVariables";
import Deployment from "../pages/docs/Deployment";
import Theming from "../pages/docs/Theming";
import StateManagement from "../pages/docs/StateManagement";
import ESLintAndPrettier from "../pages/docs/ESLintAndPrettier";
import Support from "../pages/docs/Support";
import Changelog from "../pages/docs/Changelog";
import APICalls from "../pages/docs/APICalls";

import * as constants from "@constants";

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Pages",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/dashboard/default",
      name: "Default",
      guard: AuthGuard,
      component: Default,
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      guard: AuthGuard,
      component: Analytics,
    },
    {
      path: "/dashboard/saas",
      name: "SaaS",
      guard: AuthGuard,
      component: SaaS,
    },
  ],
  component: null,
};

// If you need to add route without sidebar, add it here
const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: `${constants.REPORTS_TABLE_TEAM_PATH}/:teamId`,
      name: "Profile",
      component: Profile,
      guard: AuthGuard,
    },
    {
      path: `${constants.REPORTS_PENDING_ENTITY_PATH}/:targetServiceId`,
      name: "Pending Entity",
      component: PendingEntity,
      guard: AuthGuard,
    },
    {
      path: `${constants.LOGS_PATH}/:mosaicTeamId`,
      component: Logs,
      guard: AuthGuard,
    },
    {
      path: constants.REPORTS_INTEGRATION_DETAILS_PATH_DEEP_LINK,
      name: "Team-specific Integration",
      component: TeamIntegration,
      guard: AuthGuard,
    },
    {
      path: "/pages/settings",
      name: "Settings",
      guard: AuthGuard,
      component: Settings,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      guard: AuthGuard,
      component: Pricing,
    },
    {
      path: "/pages/chat",
      name: "Chat",
      guard: AuthGuard,
      component: Chat,
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      guard: AuthGuard,
      component: Blank,
    },
  ],
  component: null,
};

const projectsRoutes = {
  id: "Projects",
  path: "/projects",
  icon: <Briefcase />,
  badge: "8",
  component: Projects,
  guard: AuthGuard,
  children: null,
};

const invoiceRoutes = {
  id: "Invoices",
  path: "/invoices",
  icon: <CreditCard />,
  children: [
    {
      path: "/invoices",
      name: "List",
      guard: AuthGuard,
      component: InvoiceList,
    },
    {
      path: "/invoices/detail",
      name: "Details",
      guard: AuthGuard,
      component: InvoiceDetails,
    },
  ],
  component: null,
};

const orderRoutes = {
  id: "Orders",
  path: "/orders",
  icon: <ShoppingCart />,
  component: Orders,
  guard: AuthGuard,
  children: null,
};

const tasksRoutes = {
  id: "Tasks",
  path: "/tasks",
  icon: <CheckSquare />,
  badge: "17",
  component: Tasks,
  guard: AuthGuard,
  children: null,
};

const calendarRoutes = {
  id: "Calendar",
  path: "/calendar",
  icon: <CalendarIcon />,
  component: Calendar,
  guard: AuthGuard,
  children: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const componentsRoutes = {
  id: "Components",
  path: "/components",
  header: "Elements",
  icon: <Grid />,
  children: [
    {
      path: "/components/accordion",
      name: "Accordion",
      guard: AuthGuard,
      component: Accordion,
    },
    {
      path: "/components/alerts",
      name: "Alerts",
      guard: AuthGuard,
      component: Alerts,
    },
    {
      path: "/components/avatars",
      name: "Avatars",
      guard: AuthGuard,
      component: Avatars,
    },
    {
      path: "/components/badges",
      name: "Badges",
      guard: AuthGuard,
      component: Badges,
    },
    {
      path: "/components/buttons",
      name: "Buttons",
      guard: AuthGuard,
      component: Buttons,
    },
    {
      path: "/components/cards",
      name: "Cards",
      guard: AuthGuard,
      component: Cards,
    },
    {
      path: "/components/chips",
      name: "Chips",
      guard: AuthGuard,
      component: Chips,
    },
    {
      path: "/components/dialogs",
      name: "Dialogs",
      guard: AuthGuard,
      component: Dialogs,
    },
    {
      path: "/components/lists",
      name: "Lists",
      guard: AuthGuard,
      component: Lists,
    },
    {
      path: "/components/menus",
      name: "Menus",
      guard: AuthGuard,
      component: Menus,
    },
    {
      path: "/components/pagination",
      name: "Pagination",
      guard: AuthGuard,
      component: Pagination,
    },
    {
      path: "/components/progress",
      name: "Progress",
      guard: AuthGuard,
      component: Progress,
    },
    {
      path: "/components/snackbars",
      name: "Snackbars",
      guard: AuthGuard,
      component: Snackbars,
    },
    {
      path: "/components/tooltips",
      name: "Tooltips",
      guard: AuthGuard,
      component: Tooltips,
    },
  ],
  component: null,
};

const formsRoutes = {
  id: "Forms",
  path: "/forms",
  icon: <CheckSquare />,
  children: [
    {
      path: "/forms/pickers",
      name: "Pickers",
      guard: AuthGuard,
      component: Pickers,
    },
    {
      path: "/forms/selection-controls",
      name: "Selection Controls",
      guard: AuthGuard,
      component: SelectionCtrls,
    },
    {
      path: "/forms/selects",
      name: "Selects",
      guard: AuthGuard,
      component: Selects,
    },
    {
      path: "/forms/text-fields",
      name: "Text Fields",
      guard: AuthGuard,
      component: TextFields,
    },
    {
      path: "/forms/dropzone",
      name: "Dropzone",
      guard: AuthGuard,
      component: Dropzone,
    },
    {
      path: "/forms/editors",
      name: "Editors",
      guard: AuthGuard,
      component: Editors,
    },
    {
      path: "/forms/formik",
      name: "Formik",
      guard: AuthGuard,
      component: Formik,
    },
  ],
  component: null,
};

const tablesRoutes = {
  id: "Tables",
  path: "/tables",
  icon: <List />,
  children: [
    {
      path: "/tables/simple-table",
      name: "Simple Table",
      guard: AuthGuard,
      component: SimpleTable,
    },
    {
      path: "/tables/advanced-table",
      name: "Advanced Table",
      guard: AuthGuard,
      component: AdvancedTable,
    },
  ],
  component: null,
};

const iconsRoutes = {
  id: "Icons",
  path: "/icons",
  icon: <Heart />,
  children: [
    {
      path: "/icons/material-icons",
      name: "Material Icons",
      guard: AuthGuard,
      component: MaterialIcons,
    },
    {
      path: "/icons/feather-icons",
      name: "Feather Icons",
      guard: AuthGuard,
      component: FeatherIcons,
    },
  ],
  component: null,
};

const chartRoutes = {
  id: "Charts",
  path: "/charts",
  icon: <PieChart />,
  component: Chartjs,
  guard: AuthGuard,
  children: null,
};

const mapsRoutes = {
  id: "Maps",
  path: "/maps",
  icon: <Map />,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      guard: AuthGuard,
      component: GoogleMaps,
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      guard: AuthGuard,
      component: VectorMaps,
    },
  ],
  component: null,
};

const documentationRoutes = {
  id: "Documentation",
  path: "/documentation",
  header: "Mosaic Galaxy",
  icon: <BookOpen />,
  children: [
    {
      path: "/documentation/welcome",
      name: "Welcome",
      guard: AuthGuard,
      component: Welcome,
    },
    {
      path: "/documentation/getting-started",
      name: "Getting Started",
      guard: AuthGuard,
      component: GettingStarted,
    },
    {
      path: "/documentation/environment-variables",
      name: "Environment Variables",
      guard: AuthGuard,
      component: EnvironmentVariables,
    },
    {
      path: "/documentation/deployment",
      name: "Deployment",
      guard: AuthGuard,
      component: Deployment,
    },
    {
      path: "/documentation/theming",
      name: "Theming",
      guard: AuthGuard,
      component: Theming,
    },
    {
      path: "/documentation/state-management",
      name: "State Management",
      guard: AuthGuard,
      component: StateManagement,
    },
    {
      path: "/documentation/api-calls",
      name: "API Calls",
      guard: AuthGuard,
      component: APICalls,
    },
    {
      path: "/documentation/eslint-and-prettier",
      name: "ESLint & Prettier",
      guard: AuthGuard,
      component: ESLintAndPrettier,
    },
    {
      path: "/documentation/support",
      name: "Support",
      guard: AuthGuard,
      component: Support,
    },
  ],
  component: null,
};

const changelogRoutes = {
  id: "Changelog",
  path: "/changelog",
  badge: "v2.0.3",
  icon: <List />,
  component: Changelog,
  guard: AuthGuard,
  children: null,
};

/* ------------------------------ Routes in Use ----------------------------- */
const teamRoutes = {
  id: "Teams",
  icon: <></>,
  prod: true,
  children: [
    {
      path: constants.REPORTS_TABLE_TEAM_PATH,
      name: "Teams",
      component: Teams,
      prod: true,
    },
    {
      path: constants.REPORTS_OVERALL_INTEGRATION_DETAILS_PATH,
      name: "Integration",
      component: GeneralIntegration,
      prod: true,
    },
  ],
};

const devRoutes = {
  id: "Developer Tools",
  icon: <></>,
  prod: true,
  children: [
    {
      path: constants.DEVELOPER_TOOLS,
      name: "Fetch",
      component: Developer,
      prod: true,
    },
  ],
};

const adminRoutes = {
  id: "Administration",
  icon: <></>,
  prod: true,
  path: constants.REPORT_SUPER_ADMIN_SECTION_PATH,
  component: Administration,
};

const reportRoutes = {
  id: "Reports",
  icon: <></>,
  prod: true,
  children: [
    {
      path: constants.REPORT_UTILIZATION_PATH,
      name: "Utilization",
      component: Administration, // 
      prod: true,
    },
  ],
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  teamRoutes,
  // reportRoutes,
  devRoutes,
  adminRoutes,
  dashboardsRoutes,
  pagesRoutes, // 
  projectsRoutes,
  orderRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  teamRoutes,
  // reportRoutes,
  devRoutes,
  adminRoutes,
  dashboardsRoutes,
  projectsRoutes,
  orderRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  authRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
];
