import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchRecurlyPlan } from "GlobalModule/thunks";

import { selectAuthUser, selectAuthLoadingState } from "selectors";

const useAppOnMount = () => {
  const dispatch = useDispatch();

  // const authUser = useSelector(selectAuthUser);
  // useEffect(() => {
  //   // fetch all plan
  //   if (authUser?.auth_token && authUser?.account) {
  //     dispatch(fetchRecurlyPlan({}));
  //   }
  // }, [dispatch, authUser]);
};

export default useAppOnMount;
