import { INTERVAL, intervalsHash } from "@constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as IntegrationService from "IntegrationModule/services";
import * as ProfileService from "ProfileModule/services";
import * as Types from "IntegrationModule/types";
import {
  generateDateBeforeToday,
  makeIntegrationTeamInfoMappings,
} from "utils";
import { AppStateType } from "reducers";
import { updateTeamsInfoMapping } from "ReportsModule/actionCreators";

export const fetchActiveIntegrations = createAsyncThunk(
  "fetchActiveIntegrations",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getActiveIntegrations(
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchIntegrationServiceMetrics = createAsyncThunk(
  "fetchIntegrationServiceMetrics",
  async (
    {
      targetService,
      offset,
      limit,
      interval,
      isInitialFetch,
    }: Types.fetchIntegrationServiceMetricsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const date = generateDateBeforeToday(
      INTERVAL.DAY,
      intervalsHash[interval].value
    );
    const queryParams = {
      targetService,
      offset,
      limit,
      date,
    };
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;

      const response = await IntegrationService.getIntegrationServiceMetrics(
        queryParams,
        {},
        auth_token
      );
      const { teams }: Types.ServiceMetricsAPIResponseData = response.data;

      const initialAccumlatorState: {
        mosaicTeamIds: Array<number>;
        targetServiceIds: Array<number>;
      } = {
        mosaicTeamIds: [],
        targetServiceIds: [],
      };
      const { mosaicTeamIds } = teams.reduce((acc, curr) => {
        const {
          mosaicTeamId,
          targetServiceId,
        }: Types.ServiceMetricsAPITeamsField = curr;
        acc.mosaicTeamIds.push(mosaicTeamId);
        acc.targetServiceIds.push(targetServiceId);
        return acc;
      }, initialAccumlatorState);

      if (mosaicTeamIds.length > 0) {
        const config = {
          params: {
            ids: mosaicTeamIds,
            limit: mosaicTeamIds.length,
          },
        };
        const teamsForCreatingNewMappingResults = await ProfileService.getTeamByID(
          config,
          user?.auth_token
        );
        dispatch(
          updateTeamsInfoMapping(
            makeIntegrationTeamInfoMappings(
              teamsForCreatingNewMappingResults.data.teams,
              targetService
            )
          )
        );
      }
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchIntegrationServiceMetricsTotal = createAsyncThunk(
  "fetchIntegrationServiceMetricsTotal",
  async (
    {
      interval,
      targetService,
    }: Types.fetchIntegrationServiceMetricsTotalParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const subtractBy = intervalsHash[interval].value;
    const date = generateDateBeforeToday(INTERVAL.DAY, subtractBy); // because we are subtracting strictly by 7, 14, 30 DAYS

    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getIntegrationServiceMetricsTotal(
        targetService,
        date,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

/* -------------------------------- Error log ------------------------------- */

export const fetchNetworkErrorLog = createAsyncThunk(
  "fetchNetworkErrorLog",
  async (
    params: Types.fetchNetworkErrorLogParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const body = {
      targetService: params.targetService,
      mosaicTeamIds: params.mosaicTeamIds,
      startDate: params.startDate,
      limit: params.limit,
      offset: params.offset,
    };
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getNetworkErrorLog(body, {}, auth_token);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchAgentErrorLog = createAsyncThunk(
  "fetchAgentErrorLog",
  async (
    {
      agentOnly,
      targetService,
      offset,
      limit,
      startDate,
      isInitialFetch,
    }: Types.fetchAgentErrorLogParams,
    { dispatch, getState, rejectWithValue }
  ) => {

    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const auth_token = user?.auth_token;
    const body = {
      agentOnly: agentOnly || targetService === "quickbooks-desktop",
      targetServiceId: "all",
      targetService,
      limit,
      offset,
      startDate,
    };
    try {
      const response = await IntegrationService.postRecentErrors(body, {}, auth_token);
      return response.data.response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
