import { AppStateType } from "reducers";
import { createSelector } from "reselect";
import keyBy from "lodash/keyBy";
import { RecurlyPlan } from "GlobalModule/types";
import * as thunks from "GlobalModule/thunks";

export const selectGlobalReducerState = (state: AppStateType) =>
  state.globalReducer;

const selectGlobalReducerRequestStatusesAndErrors = createSelector(
  selectGlobalReducerState,
  (state) => state.requestStatusesAndErrors
);

export const selectIsDevMode = createSelector(
  selectGlobalReducerState,
  (state) => state.isDevMode
);

export const selectOpenRoutes = createSelector(
  selectGlobalReducerState,
  (state) => state.openRoutes
);

export const selectRecurlyPlans = createSelector(
  selectGlobalReducerState,
  (state) => {
    const formattedPlans = state.recurlyPlans.map((plan: RecurlyPlan) => ({
      value: plan.code,
      label: plan.name,
      ...plan,
    }));

    const formattedPlansHash = keyBy(formattedPlans, "code");

    return {
      plans: state.recurlyPlans,
      formattedPlans,
      plansHash: formattedPlansHash,
    };
  }
);

export const selectCreateTeamData = createSelector(
  selectGlobalReducerRequestStatusesAndErrors,
  (requestStatusesAndErrors) => ({
    ...requestStatusesAndErrors[
      thunks.createTeam.typePrefix
    ],
  })
);
