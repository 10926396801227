import {
  signIn as signInEntity,
  resetPassword as resetPasswordEntity,
  exchangeToken as exchangeTokenEntity,
  fetchMe as fetchMeEntity,
} from "actionCreators";
import {
  signIn as authSignin,
  resetPassword,
  getAuthToken,
  getMe,
} from "services";

import { ResetPasswordType, SignInType, SignUpType } from "types";

import { AUTH_ERRORS } from "@constants";

import { AppDispatchType } from "store";

import { callApi } from "thunk";
import { AppStateType } from "reducers";

/**
 * Deprecated. We are using auth token to sign in from now
 */
export const signIn = (credentials: SignInType) => async (
  dispatch: AppDispatchType
) => {
  const api = () => authSignin(credentials);

  return callApi(
    api,
    {
      ...signInEntity(),
      requestPayload: { ...credentials },
      dispatch,
    },
    {
      enhanceErrorFn: (e: Record<any, any>) => {
        return {
          ...e,
          message:
            "Sign in failed. You either are not a super admin, or entered incorrect credentials",
        };
      },
      errorType: AUTH_ERRORS.AUTHENTICATION_ERROR,
    }
  );
};

export const resetPasswordThunk = (credentials: ResetPasswordType) => async (
  dispatch: AppDispatchType,
  getState: () => AppStateType
) => {
  const api = () => resetPassword(credentials);
  return callApi(
    api,
    {
      ...resetPasswordEntity(),
      requestPayload: { ...credentials },
      dispatch,
    },
    {
      enhanceErrorFn: null,
      errorType: AUTH_ERRORS.RESET_PASSWORD_ERROR,
    }
  );
};

export const fetchMeThunk = (authToken: string) => async (
  dispatch: AppDispatchType,
  getState: () => AppStateType
) => {
  const api = () => getMe(authToken as string);
  return callApi(
    api,
    {
      ...fetchMeEntity(),
      requestPayload: {},
      dispatch,
    },
    {
      enhanceErrorFn: null,
      errorType: AUTH_ERRORS.FETCH_ME,
    }
  );
};

export const exchangeTokenThunk = (tempToken: string) => async (
  dispatch: AppDispatchType,
  getState: () => AppStateType
) => {
  const api = () => getAuthToken(tempToken);
  const { response, error }: any = await callApi(
    api,
    {
      ...exchangeTokenEntity(),
      requestPayload: { tempToken },
      dispatch,
    },
    {
      enhanceErrorFn: null,
      errorType: AUTH_ERRORS.AUTHENTICATION_ERROR,
    }
  );
};
