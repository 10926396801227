import {
  PENDING_MAPPING_STATUS,
  pendingOnMosaicHash,
  pendingOnTargetHash,
} from "@constants";

export const isDataTypePending = (dataTypeMappingStatus: string) => {
  return PENDING_MAPPING_STATUS[dataTypeMappingStatus];
};

export const isPendingOnMosaic = (mappingStatus: string) =>
  !!pendingOnMosaicHash[mappingStatus];

export const isPendingOnTarget = (mappingStatus: string) =>
  !!pendingOnTargetHash[mappingStatus];
