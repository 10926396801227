import axios from "axios";

import { rootUrlAPI, rootUrlIntegrationAPI } from "@constants";
import qs from "qs";

const myAxios = axios.create({
  validateStatus: (status) => status < 500,
});

const paramsSerializer = (params: any) => {
  return qs.stringify(params, {
    arrayFormat: "brackets",
  });
};

const destructureConfig = (
  custom_config: Record<string, any> = {},
  axios_config: Record<string, any> = {}
) => {
  return {
    ...axios_config,
    ...custom_config,
    headers: {
      ...axios_config.headers,
      ...custom_config.headers,
    },
  };
};

export const axios_integration_server_config = {
  baseURL: rootUrlIntegrationAPI,
};

myAxios.defaults.baseURL = rootUrlAPI;
myAxios.defaults.paramsSerializer = paramsSerializer;

export const getAPI = (
  url: string,
  config: Record<string, any>,
  token: string = ""
): Promise<any> => {
  return new Promise((resolve, reject) => {
    myAxios
      .get(url, {
        ...config,
        headers: {
          Authorization: `Bearer ${token}`,
          ...config.headers,
        },
      })
      .then((response) => {
        if (response.status >= 400) {
          reject(response);
        } else {
          resolve(response);
        }
      })
      .catch((error: any) => {
        reject(handleError(error));
      });
  });
};

export const postAPI = (
  url: string,
  data: any,
  config: Record<string, any>,
  token: string = ""
): Promise<any> => {
  return new Promise((resolve, reject) => {
    myAxios
      .post(url, data, {
        transformRequest: function (data: any) {
          return JSON.stringify(data);
        },
        ...config,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          ...config.headers,
        },
      })
      .then((response) => {
        if (response.status >= 400) {
          reject(response);
        } else {
          resolve(response);
        }
      })
      .catch((error: any) => reject(handleError(error)));
  });
};

export const putAPI = (
  url: string,
  data: any,
  config: Record<string, any>,
  token: string = ""
): Promise<any> => {
  return new Promise((resolve, reject) => {
    myAxios
      .put(url, data, {
        transformRequest: function (data: any) {
          return JSON.stringify(data);
        },
        ...config,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          ...config.headers,
        },
      })
      .then((response) => {
        if (response.status >= 400) {
          reject(response);
        } else {
          resolve(response);
        }
      })
      .catch((error: any) => reject(handleError(error)));
  });
};

export const getApiIntegrationServer = (
  url: string,
  config: Record<string, any>,
  token: string = ""
) =>
  getAPI(
    url,
    destructureConfig(config, axios_integration_server_config),
    token
  );

export const postApiIntegrationServer = (
  url: string,
  data: any,
  config: Record<string, any>,
  token: string = ""
) =>
  postAPI(
    url,
    data,
    destructureConfig(config, axios_integration_server_config),
    token
  );

export const putApiIntegrationSever = (
  url: string,
  data: any,
  config: Record<string, any>,
  token: string = ""
) =>
  putAPI(
    url,
    data,
    destructureConfig(config, axios_integration_server_config),
    token
  );

const ERRORS_CODE_MAPPING = {
  403: "You are not authorized to access team's resources. Please log out and sign-in using another account",
  404: "Resources not found",
};

/**
 * General error handler. Custom error message can be changed in specific thunk's error handler
 */
export const handleError = (error: any) => {
  /**
   * Standardize error with 4 properties (message, name, status (code), devMessage, type)
   * message (Message to be displayed to user) + name : Has already existed by default
   * status (code) + devMessage + type (one of error constants to identify within reducer): Not exist
   * -> devMessage is to see what has gone wrong (which is usually available as a property of error.response if it is an axios error)
   *
   */

  /** Generalize axios error */
  if (error.isAxiosError) {
    error.message =
      ERRORS_CODE_MAPPING[
        error?.response?.status as keyof typeof ERRORS_CODE_MAPPING
      ] || "Failed to retrieve data. Please try again";
    error.devMessage = `Failed to fetch data on endpoint ${error.config.url}. Error code: ${error?.response?.status}`;
    error.status = error?.response?.status;
  }

  /** Pass error to thunk */
  return error;
};
