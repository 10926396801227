import * as ReportService from "ReportsModule/services";
import * as ProfileService from "ProfileModule/services";
import * as EmailValidator from "email-validator";
import { AppStateType } from "reducers";

import { GenericTeamInterface } from "types";
import * as Types from "ReportsModule/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setFilters } from "actionCreators";
import * as constants from "@constants";
import omit from "lodash/omit";
import { retainNonNilValues } from "utils";

const initialFetchOption = {
  page: 0,
  offset: 0,
};

const tableSortOptionsHash = {
  last_user_activity_at: {
    backendParams: "last_user_activity_time",
    frontendParams: "last_user_activity_at",
  },
};

export const fetchReportsTeams = createAsyncThunk(
  "fetchReportsTeams",
  async (
    {
      orderBy,
      orderDirection,
      search_text,
      ids,
      tags,
      offset,
      page,
      isInitialFetch,
      isSearchMode,
      onSuccess = [],
    }: Types.fetchReportsTeamsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;

      const auth_token = user?.auth_token;

      /**
       * FE sort - keep same
       * BE sort - After fetching, must return to frontend params
       */
      const sortOption =
        tableSortOptionsHash[orderBy as keyof typeof tableSortOptionsHash];

      const searchByName = !ids?.length;

      const searchValue = {
        ...(searchByName
          ? {
              search_text,
              include_personal: EmailValidator.validate(search_text || ""),
            }
          : { search_text: "" }),
        ...(!searchByName ? { ids } : { ids: [] }),
      };

      const apiConfig = {
        params: {
          offset: isInitialFetch ? initialFetchOption.offset : offset,
          page: isInitialFetch ? initialFetchOption.page : page,
          order_by: sortOption?.backendParams || orderBy, // This is the case for last_user_activity_time
          order_direction: orderDirection,
          ...searchValue,
          tags,
        },
      };

      const response = await ReportService.getReportsTeams(
        apiConfig,
        auth_token
      );
      const processedData = response.data.teams.map(
        (team: GenericTeamInterface) =>
          !team.last_user_activity_at
            ? { ...team, last_user_activity_at: "" }
            : team
      );

      dispatch(
        setFilters({
          filterId: constants.filterIds.teamsReports,
          ...omit(apiConfig.params, "order_by", "order_direction"),
          total: response.data.total,
          isSearchMode,
          orderDirection,
          orderBy:
            (sortOption && sortOption.frontendParams) ||
            apiConfig.params.order_by,
          ...searchValue,
        })
      );

      onSuccess.forEach(({ action, selector }) =>
        action(selector({ response }))
      );

      return {
        teams: processedData,
        isInitialFetch,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchTeamsHistoricalUtilization = createAsyncThunk(
  "fetchTeamsHistoricalUtilization",
  async (
    params: Types.fetchTeamsHistoricalUtilizationParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const paramsToUse = retainNonNilValues({
        team_ids: params.team_ids,
        threshold: params.threshold,
        compliant_start_period: params.compliant_start_period,
        limit: params.limit,
        offset: params.offset,
        include_zeroes: params.include_zeroes,
        sort_by_utilization: params.sort_by_utilization,
        sort_direction: params.sort_direction,
      });
      const response = await ReportService.getTeamsHistoricalUtilization(
        paramsToUse,
        auth_token as string
      );

      params.onSuccess &&
        params.onSuccess.forEach(({ action, selector }) =>
          action(selector({ response, params }))
        );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchTeamsForTeamsInfoMapping = createAsyncThunk(
  "fetchTeamsForTeamsInfoMapping",
  async (
    params: Types.fetchTeamsForTeamsInfoMappingParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const { teamIds } = params;
      const config = {
        params: { ids: teamIds, limit: teamIds.length },
      };
      const response = await ProfileService.getTeamByID(config, auth_token);

      return response.data.teams;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
