import {
  SIGN_IN_REQUEST,
  SIGN_IN_FAILED,
  SIGN_IN_SUCCESS,
  RESET_AUTH,
  AUTH_LOADING_STATE,
  RESET_PASSWORD,
  EXCHANGE_TOKEN,
  AUTH_ERRORS,
  FETCH_ME,
} from "@constants";

import { ActionInterface, ErrorInterface } from "types";
import { createError } from "utils";

export type UserType = {
  auth_token: string;
  temp_token: string;
  is_super_admin?: boolean;
  isAuthTokenFetched?: boolean;
  account: {
    id: string;
    email: string;
    slug: string;
    default_team: {
      id: number;
    };
    teams_roles: Record<number,number[]>
  };
};

export type AuthStateType = {
  user?: UserType;
  loadingState: {
    [AUTH_LOADING_STATE.AUTHENTICATION_LOADING]: boolean;
    [AUTH_LOADING_STATE.RESET_PASSWORD_LOADING]: boolean;
    [AUTH_LOADING_STATE.FETCH_ME]: boolean;
  };
  error: {
    [AUTH_ERRORS.AUTHENTICATION_ERROR]?: ErrorInterface;
    [AUTH_ERRORS.RESET_PASSWORD_ERROR]?: boolean;
    [AUTH_ERRORS.FETCH_ME]?: boolean;
  };
};

const INITIAL_STATE = {
  user: undefined,
  loadingState: {
    [AUTH_LOADING_STATE.AUTHENTICATION_LOADING]: false,
    [AUTH_LOADING_STATE.RESET_PASSWORD_LOADING]: false,
    [AUTH_LOADING_STATE.FETCH_ME]: false,
  },
  error: {
    [AUTH_ERRORS.AUTHENTICATION_ERROR]: undefined,
    [AUTH_ERRORS.RESET_PASSWORD_ERROR]: undefined,
    [AUTH_ERRORS.FETCH_ME]: false,
  },
};

export default function reducer(
  state: AuthStateType = INITIAL_STATE,
  actions: ActionInterface
): AuthStateType {
  const { type, payload, error } = actions;

  /** Create new error state */
  const ERROR = createError(state.error, error);
  const LOADING_STATE = {
    ...state.loadingState,
  };

  switch (type) {
    /** Request */
    case SIGN_IN_REQUEST:
    case EXCHANGE_TOKEN.REQUEST: {
      LOADING_STATE[AUTH_LOADING_STATE.AUTHENTICATION_LOADING] = true;

      return {
        ...state,
        loadingState: LOADING_STATE,
      };
    }

    case FETCH_ME.REQUEST: {
      LOADING_STATE[AUTH_LOADING_STATE.FETCH_ME] = true;

      return {
        ...state,
        loadingState: LOADING_STATE,
      };
    }

    case RESET_PASSWORD.REQUEST:
      LOADING_STATE[AUTH_LOADING_STATE.RESET_PASSWORD_LOADING] = true;

      return {
        ...state,
        loadingState: LOADING_STATE,
      };

    /** Success */

    /** Deprecated */
    case SIGN_IN_SUCCESS: {
      LOADING_STATE[AUTH_LOADING_STATE.AUTHENTICATION_LOADING] = false;
      ERROR[AUTH_ERRORS.AUTHENTICATION_ERROR] = undefined;
      const { responsePayload } = payload;
      return {
        ...state,
        user: {
          ...responsePayload.data,
          is_super_admin: true,
        },
        loadingState: LOADING_STATE,
        error: ERROR,
      };
    }

    case RESET_PASSWORD.SUCCESS:
      LOADING_STATE[AUTH_LOADING_STATE.RESET_PASSWORD_LOADING] = false;
      ERROR[AUTH_ERRORS.RESET_PASSWORD_ERROR] = undefined;
      return {
        ...state,
        loadingState: LOADING_STATE,
        error: ERROR,
      };

    case EXCHANGE_TOKEN.SUCCESS: {
      LOADING_STATE[AUTH_LOADING_STATE.AUTHENTICATION_LOADING] = false;
      ERROR[AUTH_ERRORS.AUTHENTICATION_ERROR] = undefined;
      const { responsePayload } = actions.payload;

      return {
        ...state,
        user: {
          ...responsePayload.data,
        },
        loadingState: LOADING_STATE,
        error: ERROR,
      };
    }

    case FETCH_ME.SUCCESS: {
      LOADING_STATE[AUTH_LOADING_STATE.FETCH_ME] = false;
      ERROR[AUTH_ERRORS.FETCH_ME] = undefined;
      const { responsePayload } = actions.payload;

      return {
        ...state,
        user: {
          ...state.user,
          ...responsePayload.data,
        },
        loadingState: LOADING_STATE,
      };
    }

    /** Failed */

    /** Deprecated */
    case SIGN_IN_FAILED:
      LOADING_STATE[AUTH_LOADING_STATE.AUTHENTICATION_LOADING] = false;

      return {
        ...state,
        error: ERROR,
        loadingState: LOADING_STATE,
      };

    case RESET_PASSWORD.FAILURE:
      LOADING_STATE[AUTH_LOADING_STATE.RESET_PASSWORD_LOADING] = false;
      return {
        ...state,
        error: ERROR,
        loadingState: LOADING_STATE,
      };

    case RESET_AUTH:
      return {
        ...INITIAL_STATE,
      };

    case EXCHANGE_TOKEN.FAILED: {
      LOADING_STATE[AUTH_LOADING_STATE.AUTHENTICATION_LOADING] = false;

      return {
        ...state,
        loadingState: LOADING_STATE,
        error: ERROR,
      };
    }

    case FETCH_ME.FAILED: {
      LOADING_STATE[AUTH_LOADING_STATE.FETCH_ME] = false;

      return {
        ...state,
        loadingState: LOADING_STATE,
        error: ERROR,
      };
    }

    default:
      return state;
  }
}
