import keyBy from "lodash/keyBy";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppStateType } from "reducers";
import { isSuperAdmin } from "utils";
import { updateTeamsInfoMapping } from "ReportsModule/actionCreators";
import * as ProfileService from "ProfileModule/services";
import * as ReportsService from "ReportsModule/services";
import * as Types from "ProfileModule/types";
import * as constants from "@constants";

export const fetchTeamMembers = createAsyncThunk(
  "fetchTeamMembers",
  async (
    { teamId }: Types.fetchTeamMembersParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;

      const response = await ProfileService.getTeamMember(
        teamId,
        {},
        auth_token
      );
      return response.data.team_members;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchTeam = createAsyncThunk(
  "fetchTeam",
  async (
    { teamId }: Types.fetchTeamParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const config = {
        params: {
          ids: [teamId],
        },
      };
      const response = await ProfileService.getTeamByID(config, auth_token);
      dispatch(updateTeamsInfoMapping(keyBy(response.data.teams, "id")));
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateTeam = createAsyncThunk(
  "updateTeam",
  async (
    params: Types.updateTeamParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await ReportsService.updateTeam(
        params,
        auth_token as string
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

/* -------------------------------------------------------------------------- */

export const updateTeamMember = createAsyncThunk(
  "updateTeamMember",
  async (
    params: Types.updateTeamMembersParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const { teamMembershipId } = params;
      const response = await ProfileService.updateTeamMember(
        teamMembershipId,
        params,
        {},
        auth_token as string
      );
      if (
        params.hasRoleChanged &&
        isSuperAdmin(response.data.team_member.role_ids)
      ) {
        window.location.href = constants.REPORT_SUPER_ADMIN_SECTION_PATH;
      }
      return response.data;
    } catch (e) {
      return e;
    }
  }
);

export const fetchTeamApiToken = createAsyncThunk(
  "fetchTeamApiToken",
  async (
    { teamId, domain }: Types.fetchTeamApiTokenParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const body = {
        team_id: teamId,
        domain,
      };
      const response = await ReportsService.fetchTeamApiToken(
        body,
        auth_token as string
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const resetMemberPassword = createAsyncThunk(
  "resetMemberPassword",
  async (
    { emails }: Types.resetMemberPasswordParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const body = {
        emails,
      };
      const response = await ProfileService.resetMemberPassword(
        body,
        {},
        auth_token as string
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchAgentToken = createAsyncThunk(
  "fetchAgentToken",
  async (
    params: Types.fetchAgentTokenParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const response = await ProfileService.getAgentToken(params);
      return response.data.agentToken;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

// TO COME
export const checkIntegrationConnection = createAsyncThunk(
  "checkIntegrationConnection",
  async (
    params: Types.fetchAgentTokenParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      // const response = await ProfileService.getAgentToken(params);
      // return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const provisionCustomer = createAsyncThunk(
  "provisionCustomer",
  async (
    params: Types.provisionCustomerParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const response = await ProfileService.provisionCustomer(params);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setSingleUserTemporaryPassword = createAsyncThunk(
  "setSingleUserTemporaryPassword",
  async (
    params: Types.setSingleUserTemporaryPasswordParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const token = user?.auth_token;
      const response = await ProfileService.setSingleUserTemporaryPassword(
        params,
        {},
        token as string
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const bulkActivateAccounts = createAsyncThunk(
  "bulkActivateAccounts",
  async (
    params: Types.bulkActivateAccountsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const token = user?.auth_token;
      const response = await ProfileService.bulkActivateAccounts(
        params,
        {},
        token as string
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
