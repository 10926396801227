import { createReducer } from "@reduxjs/toolkit";
import * as actions from "GlobalModule/actionCreators";
import * as thunkActions from "GlobalModule/thunks";
import * as Types from "GlobalModule/types";
import { getTeamProfileUrl } from "utils/url";
import { sidebarRoutes } from "../../../routes";

export type GlobalStateType = {
  isDevMode: boolean;
  openRoutes: Record<number, boolean>;
  recurlyPlans: never[] | Types.RecurlyPlan[];
  requestStatusesAndErrors: {
    [x: string]: {
      isRequesting: boolean;
      error: Record<string, any>;
    };
  };
};

const defaultOpenRoutes = sidebarRoutes.reduce(
  (acc, _, index) => {
    acc[index] = true;
    return acc;
  },
  {} as Record<number, boolean>
);

const INITIAL_STATE: GlobalStateType = {
  isDevMode: false,
  openRoutes: defaultOpenRoutes,
  recurlyPlans: [],
  requestStatusesAndErrors: {
    [thunkActions.createTeam.typePrefix]: {
      isRequesting: false,
      error: {},
    },
  },
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(actions.toggleDevMode, (state, _) => {
    state.isDevMode = !state.isDevMode;
  });
  builder.addCase(actions.updateOpenRoutes, (state, { payload }) => {
    state.openRoutes = payload;
  });
  /* ------------------------------- Create Team ------------------------------ */
  builder.addCase(thunkActions.createTeam.pending, (state, { payload }) => {
    state.requestStatusesAndErrors[thunkActions.createTeam.typePrefix] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(thunkActions.createTeam.fulfilled, (state, { payload }) => {
    state.requestStatusesAndErrors[thunkActions.createTeam.typePrefix] = {
      isRequesting: false,
      error: {},
    };
    if (!payload.team_id || !payload.team_created) {
      alert("Create team failed, please try again");
    } else {
      window.location.href = getTeamProfileUrl({ teamId: +payload.team_id });
    }
  });
  builder.addCase(thunkActions.createTeam.rejected, (state, { payload }) => {
    state.requestStatusesAndErrors[thunkActions.createTeam.typePrefix] = {
      isRequesting: false,
      error: payload as any,
    };
    alert("Create team failed, please try again");
  });
  /* --------------------------- Fetch Recurly Plan --------------------------- */
  builder.addCase(
    thunkActions.fetchRecurlyPlan.pending,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchRecurlyPlan.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchRecurlyPlan.fulfilled,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchRecurlyPlan.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.recurlyPlans = payload;
    }
  );
  builder.addCase(
    thunkActions.fetchRecurlyPlan.rejected,
    (state, { payload, error }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchRecurlyPlan.typePrefix
      ] = {
        isRequesting: false,
        error,
      };
      state.recurlyPlans = [];
    }
  );
});
