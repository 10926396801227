import {
  REPORTS_INTEGRATION_DETAILS_PATH,
  REPORTS_TABLE_TEAM_PATH,
  LOGS_PATH,
  REPORTS_PENDING_ENTITY_PATH,
  rootUrl,
} from "@constants";

export const getTeamProfileUrl = ({ teamId }: { teamId: number }) =>
  `${REPORTS_TABLE_TEAM_PATH}/${teamId}`;

export const getPendingEntitiesUrl = ({
  targetServiceId,
}: {
  targetServiceId: number;
}) => `${REPORTS_PENDING_ENTITY_PATH}/${targetServiceId}`;

export const getNetworkLogUrl = ({
  mosaic_team_id,
  requestId,
  sessionId,
}: {
  mosaic_team_id: number;
  requestId: string | null;
  sessionId: string;
}) =>
  `${LOGS_PATH}/${mosaic_team_id}?requestId=${requestId}&sessionId=${sessionId}`;
