import { cloneDeep } from "lodash";

const defaultbarChartOptions = {
  cornerRadius: 2,
  legend: {
    display: false,
  },
  maintainAspectRatio : false,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: "black",
          min: 0,
          max: 0,
          maxTicksLimit: 5,
          stepSize: 0
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          color: "transparent",
        },
        ticks: {
          fontColor: "black",
        },
      },
    ],
  },
};

/**
 *
 * @param options - See https://www.chartjs.org/docs/3.5.0/charts/bar.html for a list of option
 * Add more options as needed
 * @returns
 */
export const generateBarChartOptions = (options: {
  maxTicksLimit?: number;
  max: number;
  min: number;
  stepSize?: number;
}) => {
  const newBarChartOptions = cloneDeep(defaultbarChartOptions);
  const { max, min, stepSize, maxTicksLimit } = options; 
  newBarChartOptions.scales.yAxes[0].ticks.maxTicksLimit = maxTicksLimit || 5;
  newBarChartOptions.scales.yAxes[0].ticks.stepSize = stepSize || Math.floor((max - min) / 5); 
  newBarChartOptions.scales.yAxes[0].ticks.max = max || 100;
  newBarChartOptions.scales.yAxes[0].ticks.min = min || 0;

  return newBarChartOptions;
};
