import { startCase } from 'lodash';

/**
 * A utility function that takes a valid numeric string, commas seperated 
 * and convert into an array of number 
 * @params {numericString} - string
 * @returns {number[]}
 */
export const parseNumericStringToArray = (numericString: string) => {
 return numericString.split(",").map((id: string) => parseInt(id.trim()));
} 

/**
 *  A utility function that removes all underscore and only capitalizes first letter of each word
 * @params {string}
 * @returns {string}
 */

export const removeUnderscoresAndCapitalizeFirstLetterOfEachWord = (str: string) => {
    return startCase(str.replace("_", " ").toLowerCase());
}