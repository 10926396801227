import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon, AlertCircle } from "react-feather";
import { debounce } from "lodash";

import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
} from "@material-ui/core";

import Switch from "@material-ui/core/Switch";

import { Menu as MenuIcon } from "@material-ui/icons";

import NotificationsDropdown from "./NotificationsDropdown";
import MessagesDropdown from "./MessagesDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";
import { toggleDevMode as _toggleDevMode } from "actionCreators";

import {
  selectIsDevMode,
  selectReportsTeamsData,
  selectTeamsUtilizationData,
} from "selectors";

import { fetchReportsTeams } from "thunk";

import { getReportsTeamSearchValue } from "utils";

import useAppNavigation from "hooks/useAppNavigation";
import useOnSuccess from "hooks/useOnSuccess";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 230px;
  }
`;

type AppBarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => {
  const dispatch = useDispatch();
  const toggleDevMode = () => dispatch(_toggleDevMode());
  const isDevMode: boolean = useSelector(selectIsDevMode);
  const { isRequesting: isFetchingTeams, filter } = useSelector(
    selectReportsTeamsData
  );
  const {
    isRequesting: isFetchingTeamsUtilization,
    error: fetchTeamsUtilizationError,
  } = useSelector(selectTeamsUtilizationData);
  const { navigateToTeamsTable, navigateToTeamProfile } = useAppNavigation();
  const { onReportTeamsFetchSuccess } = useOnSuccess();

  const inputRef = useRef(document.createElement("input"));

  const isRequesting = isFetchingTeams || isFetchingTeamsUtilization;

  const onEnterPress = debounce((key: string) => {
    const search_text = inputRef.current.value.trim();
    if (key === "Enter" && search_text) {
      const valueToSearch = getReportsTeamSearchValue(search_text);
      const { ids } = valueToSearch;

      inputRef.current.value = "";

      if (ids.length) {
        const navigateToProfile = navigateToTeamProfile(ids[0]);
        navigateToProfile();
        return;
      }

      dispatch(
        fetchReportsTeams({
          ...filter,
          ...valueToSearch,
          isInitialFetch: true,
          isSearchMode: true,
          onSuccess: onReportTeamsFetchSuccess,
        })
      );
      navigateToTeamsTable();
    }
  }, 100);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input
                  disabled={isRequesting}
                  onKeyDown={(e) => {
                    const { key } = e;
                    onEnterPress(key);
                  }}
                  inputRef={inputRef}
                  placeholder={
                    isRequesting ? "Loading..." : "Search team by ID or name"
                  }
                />
              </Search>
            </Grid>
            <Tooltip title="If the team name contains mostly number, such as 123123123, put a ' in front of the input. (i.e: '123123123)">
              <AlertCircle />
            </Tooltip>
            <Grid item xs />
            <Grid item>
              <Switch
                onChange={toggleDevMode}
                checked={isDevMode}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span style={{ display: isDevMode ? "inline" : "none" }}>
                <MessagesDropdown />
                <NotificationsDropdown />
                <LanguagesDropdown />
              </span>
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
