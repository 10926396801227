import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  REPORTS_INTEGRATION_DETAILS_PATH,
  rootUrl,
} from "@constants";
import { setTargetService } from "actionCreators";
import {
  getTeamProfileUrl,
  getPendingEntitiesUrl,
  getNetworkLogUrl
} from "utils/url";
import { getImpersonateToken, getTempToken } from "AuthModule/services";
import { selectAuthToken } from "selectors";
import * as constants from "@constants";

/**
 * A hook that returns navigation actions
 */
const useAppNavigation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token: string | undefined = useSelector(selectAuthToken);

  const navigateToTeamProfile = (teamId: string | number) => () =>
    history.push(getTeamProfileUrl({ teamId: +teamId }));

  const navigateToIntegrationDetailsPage = useCallback(
    (
      teamId: number | string,
      targetService: string,
      targetServiceId: number
    ) => () => {
      history.push(
        `${REPORTS_INTEGRATION_DETAILS_PATH}/teams/${teamId}/targetServices/${targetService}/targetServiceId/${targetServiceId}`
      );
    },
    [history]
  );

  const navigateToPendingEntitiesPage = useCallback(
    (
      targetService: string,
      targetServiceId: number,
      dataTypes: string[],
      mappingStatuses: string[]
    ) => () => {
      dispatch(
        setTargetService({
          name: targetService,
          id: targetServiceId,
          pendingEntitiesDataTypes: dataTypes,
          pendingEntitiesMappingStatuses: mappingStatuses,
        })
      );
      history.push(getPendingEntitiesUrl({ targetServiceId: targetServiceId }));
    },
    [dispatch, history]
  );

  const navigateToNetworkLog = useCallback(
    (
      mosaic_team_id: number,
      requestId: string | null,
      sessionId: string
    ) => () => {
      window.open(
        getNetworkLogUrl({mosaic_team_id, requestId, sessionId}),
        "_blank"
      );
    },
    []
  );

  const navigateToTeamsTable = () => {
    history.push(constants.REPORTS_TABLE_TEAM_PATH);
  };

  const navigateToMosaicAppWithImpersonateToken = (
    accountId: string | number
  ) => async () => {
    try {
      const {
        data: { auth_token },
      } = await getImpersonateToken(
        accountId,
        {
          params: {
            skip_onboarding: true,
          },
        },
        token
      );
      const {
        data: { temp_token },
      } = await getTempToken(auth_token);
      const redirectToMosaicUrl = temp_token
        ? `${rootUrl}/?access_token=${temp_token}`
        : `${rootUrl}/?impersonate=${auth_token}`;
      window.open(redirectToMosaicUrl);
    } catch (e: any) {
      console.error(e);
    }
  };

  return {
    navigateToTeamProfile,
    navigateToIntegrationDetailsPage,
    navigateToNetworkLog,
    navigateToPendingEntitiesPage,
    navigateToMosaicAppWithImpersonateToken,
    navigateToTeamsTable,
  };
};

export default useAppNavigation;
