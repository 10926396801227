/** REPORTS */
export const REPORTS_TABLE_TEAM_PATH = "/teams";
export const REPORTS_INTEGRATION_DETAILS_PATH = "/integration/details";
export const REPORTS_INTEGRATION_DETAILS_PATH_DEEP_LINK = `${REPORTS_INTEGRATION_DETAILS_PATH}/teams/:teamId/targetServices/:targetService/targetServiceId/:targetServiceId`;
export const REPORT_SUPER_ADMIN_SECTION_PATH = "/admin";
export const REPORTS_OVERALL_INTEGRATION_DETAILS_PATH =
  "/integration/overall";
export const REPORTS_PENDING_ENTITY_PATH = "/integration/pendingEntity";
export const LOGS_PATH = "/integration/logs";
export const DEVELOPER_TOOLS = "/developer";

export const REPORT_UTILIZATION_PATH = "/reports/utilization";
