import { AppStateType } from "reducers";
import { keyBy, orderBy } from "lodash";
import { createSelector } from "reselect";

import { MAPPING_STATUS, DATA_TYPES, filterIds } from "@constants";
import { selectSelectedTargetService, selectTeam } from "selectors";
import * as thunks from "thunk";

export const selectIntegrationRequestStatusesAndErrors = (
  state: AppStateType
) => state.integrationReducer.requestStatusesAndErrors;

const parseJson = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return {};
  }
};

/* -------------------------------------------------------------------------- */

export const selectTeamIntegrationList = (state: AppStateType) =>
  state.integrationReducer.integration_list;

export const selectTeamIntegrationHash = createSelector(
  selectTeamIntegrationList,
  (integrationList) => keyBy(integrationList, "id")
);

export const selectTeamIntegrationListData = createSelector(
  [selectTeamIntegrationList, selectIntegrationRequestStatusesAndErrors],
  (integrationList, requestStatusesAndErrors) => {
    return {
      data: integrationList,
      ...requestStatusesAndErrors[thunks.fetchTeamIntegrationList.typePrefix],
    };
  }
);

/* -------------------------------------------------------------------------- */

export const selectMappingStatusCountsByDataType = (state: AppStateType) =>
  state.integrationReducer.integration_data_types_mapping_status;

export const selectMappingStatusCountsByDataTypeData = createSelector(
  [
    selectMappingStatusCountsByDataType,
    selectIntegrationRequestStatusesAndErrors,
  ],
  (data, requestStatusesAndErrors) => {
    return {
      data,
      ...requestStatusesAndErrors[thunks.fetchMappingStatusCounts.typePrefix],
    };
  }
);

/* -------------------------------------------------------------------------- */

export const selectDataOverviewPerDataTypes = (state: AppStateType) =>
  state.integrationReducer.integration_data_overview_per_data_type;

export const selectDataOverviewPerDataTypesData = createSelector(
  [selectDataOverviewPerDataTypes, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => {
    return {
      data,
      ...requestStatusesAndErrors[
        thunks.fetchDataOverviewPerDataTypes.typePrefix
      ],
    };
  }
);

/* -------------------------------------------------------------------------- */

export const selectDataHistoryPerDataTypes = (state: AppStateType) =>
  state.integrationReducer.integration_data_history_per_data_type;

export const selectDataHistoryPerDataTypesData = createSelector(
  [selectDataHistoryPerDataTypes, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => {
    return {
      data,
      ...requestStatusesAndErrors[
        thunks.fetchDataOverviewPerDataTypes.typePrefix
      ],
    };
  }
);

/* -------------------------------------------------------------------------- */

export const selectIntegrationServerQueuesStatus = (state: AppStateType) =>
  state.integrationReducer.queues_status;

export const selectIntegrationServerQueuesStatusData = createSelector(
  [
    selectIntegrationServerQueuesStatus,
    selectIntegrationRequestStatusesAndErrors,
  ],
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[
      thunks.fetchIntegrationServerQueuesStatus.typePrefix
    ],
  })
);

/* ---------------------------------Pending Entities----------------------------------------- */
export const selectPendingEntitiesFilter = createSelector(
  (state: AppStateType) => state.filterReducer,
  (filters) => filters[filterIds.pendingEntities]
);

export const selectPendingEntities = (state: AppStateType) =>
  state.integrationReducer.pending_entities;

export const selectPendingEntitiesData = createSelector(
  selectPendingEntities,
  selectSelectedTargetService,
  selectPendingEntitiesFilter,
  selectIntegrationRequestStatusesAndErrors,
  (
    pendingEntities,
    selectedTargetService,
    filter,
    requestStatusesAndErrors
  ) => {
    const rawPendingEntities = selectedTargetService?.id
      ? pendingEntities[selectedTargetService?.id as number] || []
      : [];

    /** Sorting */
    const sortedPendingEntities = filter?.orderBy
      ? orderBy(rawPendingEntities, filter.orderBy, filter.orderDirection)
      : rawPendingEntities;

    /** Filtering */

    const filteredPendingEntities = filter
      ? sortedPendingEntities.slice(
          filter.page * filter.rowsPerPage,
          (filter.page + 1) * filter.rowsPerPage
        )
      : sortedPendingEntities;
    return {
      totalCounts: selectedTargetService?.id
        ? pendingEntities[selectedTargetService?.id as number]?.length || 0
        : 0,
      page: filter?.page,
      orderDirection: filter?.orderDirection,
      orderBy: filter?.orderBy,
      rowsPerPage: filter?.rowsPerPage,
      pendingEntities: filteredPendingEntities,
      ...requestStatusesAndErrors[thunks.fetchPendingEntities.typePrefix],
    };
  }
);

/* ------------------------------Api network log-------------------------------------------- */
const selectAllApiNetworkLog = (state: AppStateType) =>
  state.integrationReducer.api_request_log;

const selectAllApiNetworkLogData = createSelector(
  [selectAllApiNetworkLog, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => {
    return {
      data,
      ...requestStatusesAndErrors[thunks.fetchApiRequestLog.typePrefix],
    };
  }
);

const selectApiNetworkLogFilter = (state: AppStateType) =>
  state.filterReducer[filterIds.apiNetworkLog];

export const selectApiNetworkLogByTeam = createSelector(
  selectTeam,
  selectAllApiNetworkLogData,
  selectApiNetworkLogFilter,
  (selectedTeam, allApiNetworkLogs, filter) => {
    const { data, isRequesting, error } = allApiNetworkLogs;
    const { currentApiRequestId, currentMosaicTeamId } = data;

    if (!selectedTeam.id || !currentApiRequestId || !currentMosaicTeamId)
      return {
        formattedData: [],
        filter,
        error: undefined,
        isRequesting: true,
      };

    /* ---------------------------------- Error --------------------------------- */

    const errorByRequestId = (error as Record<number, any>)[
      currentMosaicTeamId
    ]?.[currentApiRequestId];

    if (errorByRequestId?.message)
      return {
        formattedData: [],
        filter,
        error: errorByRequestId,
        isRequesting: false,
      };

    /* ---------------------------------- Requesting status --------------------------------- */

    const IsRequestInProgress = (isRequesting as Record<number, any>)[
      currentMosaicTeamId
    ]?.[currentApiRequestId];

    const apiRequestLogByTeam =
      allApiNetworkLogs &&
      +currentMosaicTeamId &&
      currentApiRequestId &&
      data[currentMosaicTeamId]?.[currentApiRequestId]
        ? data[currentMosaicTeamId][currentApiRequestId]
        : [];

    const parsedApiRequestLogData = apiRequestLogByTeam.map((data) => {
      const parsedMetaData = data.metadata ? parseJson(data.metadata) : {};

      return {
        ...data,
        ...parsedMetaData,
      };
    });

    /** Sorting */
    const sortedApiNetworkLog = orderBy(
      parsedApiRequestLogData,
      filter.orderBy,
      filter.orderDirection
    );

    return {
      formattedData: sortedApiNetworkLog,
      filter,
      error,
      isRequesting: IsRequestInProgress,
    };
  }
);

/* ------------------------------Network log-------------------------------------------- */

const selectAllNetworkLog = (state: AppStateType) =>
  state.integrationReducer.network_log;

const selectAllNetworkLogData = createSelector(
  [selectAllNetworkLog, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => {
    return {
      data,
      ...requestStatusesAndErrors[thunks.fetchNetworkLog.typePrefix],
    };
  }
);

const selectNetworkLogByTeamFilter = (state: AppStateType) =>
  state.filterReducer[filterIds.networkLogByTeam];

export const selectNetworkLogByTeam = createSelector(
  selectTeam,
  selectAllNetworkLogData,
  selectNetworkLogByTeamFilter,
  (selectedTeam, allNetworkLogs, filter) => {
    const { data, isRequesting, error } = allNetworkLogs;
    const { currentSessionId, currentMosaicTeamId } = data;

    if (!selectedTeam.id || !currentSessionId || !currentMosaicTeamId)
      return {
        formattedData: [],
        filter,
        error,
        isRequesting: true,
      };

    /* ---------------------------------- Error --------------------------------- */

    const errorBySessionId = (error as Record<number, any>)[
      currentMosaicTeamId
    ]?.[currentSessionId];

    if (errorBySessionId?.message)
      return {
        formattedData: [],
        filter,
        error: errorBySessionId,
        isRequesting: false,
      };

    const networkLogByTeam =
      allNetworkLogs && currentMosaicTeamId && currentSessionId
        ? data[currentMosaicTeamId][currentSessionId]
        : [];

    /* ----------------------------- Request Status ----------------------------- */
    const IsRequestInProgress = (isRequesting as Record<number, any>)[
      currentMosaicTeamId
    ]?.[currentSessionId];

    /** Sorting */
    const sortedNetworkLogByTeam = orderBy(
      networkLogByTeam,
      filter.orderBy,
      filter.orderDirection
    );

    return {
      formattedData: sortedNetworkLogByTeam,
      filter,
      error: errorBySessionId,
      isRequesting: IsRequestInProgress,
    };
  }
);

/* ------------------------- Team Network Error Log (IS) ------------------------ */

const selectTeamNetworkErrorLogFilter = (state: AppStateType) =>
  state.filterReducer[filterIds.teamNetworkErrorLog];

const selectTeamNetworkErrorLogState = (state: AppStateType) =>
  state.integrationReducer.team_network_error_log;

const selectTeamNetworkErrorLogData = createSelector(
  [selectTeamNetworkErrorLogState, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchTeamNetworkErrorLog.typePrefix],
  })
);

export const selectTeamNetworkErrorLog = createSelector(
  selectTeamNetworkErrorLogData,
  selectTeamNetworkErrorLogFilter,
  (teamNetworkErrorLog, filter) => {
    const { data, isRequesting, error } = teamNetworkErrorLog;

    if (!data || data.length === 0 || isRequesting || error?.message) {
      return {
        formattedData: [],
        filter,
        fetchedSoFar: 0,
        isRequesting,
        error,
        total: data && data.length > 0 ? data[0].total_rows : 0,
      };
    }

    /** Parse meta data */

    const parsedTeamNetworkErrorLog = data.map((data) => {
      const parsedMetaData = data.metadata ? parseJson(data.metadata) : {};
      const parsedError = data.error ? parseJson(data.error) : {};

      return {
        ...data,
        ...parsedMetaData,
        "Meta Data": parsedMetaData,
        "All Errors": parsedError,
      };
    });

    /** Sorting */
    const sortedData = orderBy(
      parsedTeamNetworkErrorLog,
      filter.orderBy,
      filter.orderDirection
    );

    // /** Slicing */
    const startIdx = filter.offset;
    const endIdx = filter.offset + filter.limit;
    const formattedData = sortedData.slice(startIdx, endIdx);

    return {
      filter,
      total: formattedData[0].total_rows,
      formattedData,
      fetchedSoFar: data.length,
      isRequesting,
      error,
    };
  }
);

/* ------------------------- Team Agent Error / Recent Errors Log (Agent) ------------------------ */

export const selectTeamAgentErrorsFilter = createSelector(
  (state: AppStateType) => state.filterReducer,
  (filters) => filters[filterIds.teamAgentErrorLog]
);

const selectTeamAgentErrorsState = (state: AppStateType) =>
  state.integrationReducer.team_agent_error_log;

const selectTeamAgentErrorsData = createSelector(
  [selectTeamAgentErrorsState, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchTeamAgentErrorLog.typePrefix],
  })
);

export const selectTeamAgentErrors = createSelector(
  selectTeamAgentErrorsData,
  selectTeamAgentErrorsFilter,
  (teamAgentErrors, filter) => {
    const { data, isRequesting, error } = teamAgentErrors;

    if (!data || data.length === 0 || isRequesting || error?.message) {
      return {
        formattedData: [],
        filter,
        fetchedSoFar: 0,
        isRequesting,
        error,
        total: data && data.length > 0 ? data[0]?.total_rows : 0,
      };
    }
    /** Sorting */
    const sortedData = orderBy(data, filter.orderBy, filter.orderDirection);

    /** Slicing */
    const startIdx = filter.offset;
    const endIdx = filter.offset + filter.limit;
    const formattedData = sortedData.slice(startIdx, endIdx);

    return {
      filter,
      total: formattedData[0].total_rows,
      formattedData,
      fetchedSoFar: data.length,
      isRequesting,
      error,
    };
  }
);

/* -------------------------------------------------------------------------- */

export const selectSingleEntity = (state: AppStateType) =>
  state.integrationReducer.single_entity;

export const selectSingleEntityData = createSelector(
  selectSingleEntity,
  selectIntegrationRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchSingleEntity.typePrefix],
  })
);

export const selectLastTimeSync = (state: AppStateType) =>
  state.integrationReducer.last_time_sync;

export const selectLastTimeSyncData = createSelector(
  [selectLastTimeSync, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchLastTimeSync.typePrefix],
  })
);

/* -------------------------------------------------------------------------- */

export const selectIntegrationSettingsState = (state: AppStateType) =>
  state.integrationReducer.settings;

export const selectIntegrationSettingsData = createSelector(
  [selectIntegrationSettingsState, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchIntegrationSettings.typePrefix],
  })
);
