import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ReportService from "ReportsModule/services";
import * as ProfileService from "ProfileModule/services";
import { AppStateType } from "reducers";
import * as Types from "ReportsModule/types";

export const fetchSuperAdmins = createAsyncThunk(
  "fetchSuperAdmins",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await ReportService.getSuperAdmins({}, auth_token);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateSuperAdmin = createAsyncThunk(
  "updateSuperAdmin",
  async (
    params: Types.updateSuperAdminParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const { teamMembershipId, isRoleChanged } = params;
      const body = { ...params };
      const response = await ProfileService.updateTeamMember(
        teamMembershipId,
        body,
        {},
        auth_token as string
      );
      if (isRoleChanged) {
        /** Refetch new super admins  */
        dispatch(fetchSuperAdmins());
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);