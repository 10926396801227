import { useDispatch } from "react-redux";
import {
  fetchTeamsHistoricalUtilization,
  fetchTeamsForTeamsInfoMapping,
} from "thunk";
import { GenericTeamInterface } from "types";
import * as ReportsType from "ReportsModule/types";
import { setFilters } from "actionCreators";
import * as constants from "@constants";

type FetchTeamsResponse = {
  data: {
    teams: GenericTeamInterface[];
  };
};

const useOnSuccess = () => {
  const dispatch = useDispatch();

  const onReportTeamsFetchSuccess = [
    {
      action: (teamIds: number[] = []) => {
        dispatch(
          fetchTeamsHistoricalUtilization({
            team_ids: teamIds,
            isInitialFetch: true,
            offset: 0,
            threshold: constants.REPORT_TEAMS_UTILIZATION_THRESHOLD,
            compliant_start_period:
              constants.REPORT_TEAMS_UTILIZATION_COMPLIANT_START_DATE,
          })
        );
      },
      selector: ({ response }: { response: FetchTeamsResponse }) => {
        return response.data.teams.map((team) => team.id);
      },
    },
  ];

  const onReportsTeamUtilizationFetchSuccess = [
    {
      action: (teamIds: number[] = []) => {
        dispatch(
          fetchTeamsForTeamsInfoMapping({
            teamIds,
          })
        );
      },
      selector: ({ response }: { response: any }) => {
        return Object.keys(response.data);
      },
    },
    {
      action: ({
        params,
        response,
      }: {
        response: any;
        params: ReportsType.fetchTeamsHistoricalUtilizationParams;
      }) => {
        dispatch(
          setFilters({
            filterId: constants.filterIds.teamsUtilizationReports,
            offset: params.offset,
            page: params.page,
            // total: 1000, //(response?.total as number) || 0 => Just make sure to check after BE made the change
            /**
             * TODO
             * - If we ever want to use the default pagination (back and forth)
             * - Then need BE to return a total, then use that as the value for value "total" above
             * - Then use that as a total count for paginating
             * - List slicing can be done in selectTeamsUtilizationData
             */
          })
        );
      },
      selector: ({
        response,
        params,
      }: {
        response: any;
        params: ReportsType.fetchTeamsHistoricalUtilizationParams;
      }) => {
        return {
          response,
          params,
        };
      },
    },
  ];

  return {
    onReportTeamsFetchSuccess,
    onReportsTeamUtilizationFetchSuccess,
  };
};

export default useOnSuccess;
