import {
  ActionSuccessInterface,
  ActionFailedInterface,
  InitialActionInterface,
} from "types";

import { SET_FILTERS, THEME_SET } from "@constants";

/* -------------------------------------------------------------------------- */
/*                                 Depracated                                 */
/* -------------------------------------------------------------------------- */

const ACTIONS = ["REQUEST", "SUCCESS", "FAILED", "ERROR", "LOADING", "STRING"];

export const createRequestAction = (
  type: string,
  requestPayload?: Record<any, any>
): InitialActionInterface => ({
  type,
  requestPayload,
});

export const createSuccessAction = (
  type: string,
  payload: any
): ActionSuccessInterface => ({
  type,
  payload,
});

export const createFailedAction = (
  type: string,
  error: any
): ActionFailedInterface => ({
  type,
  error,
});

export type FilterConstant = {
  type: string;
  filterId: number;
};

export type SetThemeType = {
  type: typeof THEME_SET;
  payload: string;
};

export const createSetFilterAction = (params: any) =>
  createSuccessAction(SET_FILTERS, params);

export const createActionTypes = (type: string) => {
  const actionTypes = ACTIONS.reduce((acc: any, actionType: string) => {
    if (
      actionType === "LOADING" ||
      actionType === "ERROR" ||
      actionType === "STRING"
    ) {
      acc[actionType] = type;
    } else {
      acc[actionType] = `${type}_${actionType}`;
    }
    return acc;
  }, {});
  return actionTypes;
};

export const createEntityActions = (actionTypes: {
  REQUEST: string;
  SUCCESS: string;
  FAILED: string;
}) => ({
  request: (requestPayload: any) =>
    createRequestAction(actionTypes.REQUEST, requestPayload),
  success: (payload: any) => createSuccessAction(actionTypes.SUCCESS, payload),
  failure: (error: any) => createFailedAction(actionTypes.FAILED, error),
});

/* -------------------------------------------------------------------------- */
/*                                 Depracated                                 */
/* -------------------------------------------------------------------------- */


/* --------------------------------- General -------------------------------- */
export const setFilters = (params: Record<any, any>) =>
  createSetFilterAction(params);

export const setTheme = (value: string) => {
  return {
    type: THEME_SET,
    payload: value,
  };
};

export * from "ProfileModule/actionCreators";
export * from "AuthModule/actionCreators";
export * from "IntegrationModule/actionCreators";
export * from "ReportsModule/actionCreators";
export * from "GlobalModule/actionCreators";
